<template>
    <FcoLink class="fco-header-brand" :to="homeRoute" :href="fcoUrl(isSMSUser ? '/catalog/browse.html' : '/')">
        <picture>
            <source
                type="image/webp"
                media="(max-width: 767px)"
                srcset="/images/fco-logo-white-notag-260.webp, /images/fco-logo-white-notag-520.webp 2x"
            />
            <source type="image/webp" media="(min-width: 768px)" srcset="/images/fco-logo-notag-260.webp, /images/fco-logo-notag-520.webp 2x" />
            <source
                type="image/png"
                media="(max-width: 767px)"
                srcset="/images/fco-logo-white-notag-260.png, /images/fco-logo-white-notag-520.png 2x"
            />
            <source type="image/png" media="(min-width: 768px)" srcset="/images/fco-logo-notag-260.png, /images/fco-logo-notag-520.png 2x" />
            <source type="image/png" srcset="/images/fco-logo-notag-260.png" />
            <img class="qaa-header-logo" src="/images/fco-logo-notag.png" :alt="fcoM['rs.homeMain.fcoLogo']" />
        </picture>
    </FcoLink>
</template>

<script>
import { mapGetters } from 'vuex';
import FcoLink from '../FcoLink.vue';

export default {
    name: 'HeaderBrandLink',
    components: { FcoLink },
    computed: {
        ...mapGetters(['isSMSUser', 'isAuthorizedUser']),
        homeRoute() {
            if (!this.isAuthorizedUser) return '/login';
            if (this.isSMSUser) return '/catalog/browse';
            return '/';
        },
    },
};
</script>
