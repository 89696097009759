var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c("ValidationObserver", {
    attrs: {
      slim: "",
    },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function (_ref) {
          var _vm$currentShop, _vm$currentShop2
          var validate = _ref.validate
          return [
            _c(
              "FcoModal",
              {
                attrs: {
                  size: "xl",
                  "header-class": "title-bar-oswald font-weight-normal",
                  "title-text": _vm.fcoM["rs.quote.Modal.vi.header"],
                },
                on: {
                  close: function ($event) {
                    return _vm.$emit("close")
                  },
                },
              },
              [
                _c(
                  "form",
                  {
                    staticClass: "position-relative",
                    on: {
                      submit: function ($event) {
                        $event.preventDefault()
                        validate().then(_vm.saveVehicleInfo)
                      },
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "row",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "col-lg",
                          },
                          [
                            _c(
                              "FcoTitleBar",
                              {
                                staticClass: "mb-2",
                                attrs: {
                                  "bg-color": "gray",
                                },
                              },
                              [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(
                                      _vm.fcoM["common.customerInformation"]
                                    ) +
                                    "\n                        "
                                ),
                                _vm.vehicleInfo.selectedShopCustomerId
                                  ? _c(
                                      "FcoLink",
                                      {
                                        staticClass: "ml-3 text-xs",
                                        attrs: {
                                          to: `/customers/details/${_vm.vehicleInfo.selectedShopCustomerId}`,
                                          href: _vm.fcoUrl(
                                            `/customers/#/details/${_vm.vehicleInfo.selectedShopCustomerId}`
                                          ),
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.fcoM["rs.quote.vm.viewCustomer"]
                                          )
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            !_vm.vehicleInfo.selectedShopCustomerId
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "row",
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "col-sm-6 form-group",
                                      },
                                      [
                                        _c("label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.fcoM[
                                                "rs.quote.vm.selectCustomer"
                                              ]
                                            )
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "select",
                                          {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.vehicleInfo
                                                    .selectedShopCustomerId,
                                                expression:
                                                  "vehicleInfo.selectedShopCustomerId",
                                              },
                                            ],
                                            staticClass: "custom-select",
                                            on: {
                                              change: function ($event) {
                                                var $$selectedVal =
                                                  Array.prototype.filter
                                                    .call(
                                                      $event.target.options,
                                                      function (o) {
                                                        return o.selected
                                                      }
                                                    )
                                                    .map(function (o) {
                                                      var val =
                                                        "_value" in o
                                                          ? o._value
                                                          : o.value
                                                      return val
                                                    })
                                                _vm.$set(
                                                  _vm.vehicleInfo,
                                                  "selectedShopCustomerId",
                                                  $event.target.multiple
                                                    ? $$selectedVal
                                                    : $$selectedVal[0]
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "option",
                                              {
                                                domProps: {
                                                  value: 0,
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.fcoM[
                                                      "common.newCustomer"
                                                    ]
                                                  )
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _vm._l(
                                              _vm.shopCustomers,
                                              function (shopCustomer) {
                                                return _c(
                                                  "option",
                                                  {
                                                    key: shopCustomer.id,
                                                    domProps: {
                                                      value: shopCustomer.id,
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                                    " +
                                                        _vm._s(
                                                          _vm.getCustomerName(
                                                            shopCustomer
                                                          )
                                                        ) +
                                                        "\n                                "
                                                    ),
                                                  ]
                                                )
                                              }
                                            ),
                                          ],
                                          2
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "row",
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "col-sm form-group",
                                  },
                                  [
                                    _c("ValidationProvider", {
                                      attrs: {
                                        tag: "div",
                                        name: _vm.fcoM["common.firstName"],
                                        rules: {
                                          required: _vm.isCustomerNameRequired,
                                          max: 50,
                                          basicChars: true,
                                        },
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (_ref2) {
                                              var errors = _ref2.errors,
                                                classes = _ref2.classes
                                              return [
                                                _c("label", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.fcoM[
                                                        "common.firstName"
                                                      ]
                                                    )
                                                  ),
                                                ]),
                                                _vm._v(" "),
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm.vehicleInfo
                                                          .firstName,
                                                      expression:
                                                        "vehicleInfo.firstName",
                                                    },
                                                  ],
                                                  class: classes,
                                                  attrs: {
                                                    type: "text",
                                                    placeholder:
                                                      _vm.fcoM[
                                                        "common.firstName"
                                                      ],
                                                  },
                                                  domProps: {
                                                    value:
                                                      _vm.vehicleInfo.firstName,
                                                  },
                                                  on: {
                                                    input: function ($event) {
                                                      if (
                                                        $event.target.composing
                                                      )
                                                        return
                                                      _vm.$set(
                                                        _vm.vehicleInfo,
                                                        "firstName",
                                                        $event.target.value
                                                      )
                                                    },
                                                  },
                                                }),
                                                _vm._v(" "),
                                                errors[0]
                                                  ? _c(
                                                      "span",
                                                      {
                                                        staticClass: "error",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(errors[0])
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "col-sm form-group",
                                  },
                                  [
                                    _c("ValidationProvider", {
                                      attrs: {
                                        tag: "div",
                                        name: _vm.fcoM["common.lastName"],
                                        rules: {
                                          required: _vm.isCustomerNameRequired,
                                          max: 50,
                                          basicChars: true,
                                        },
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (_ref3) {
                                              var errors = _ref3.errors,
                                                classes = _ref3.classes
                                              return [
                                                _c("label", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.fcoM[
                                                        "common.lastName"
                                                      ]
                                                    )
                                                  ),
                                                ]),
                                                _vm._v(" "),
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm.vehicleInfo
                                                          .lastName,
                                                      expression:
                                                        "vehicleInfo.lastName",
                                                    },
                                                  ],
                                                  class: classes,
                                                  attrs: {
                                                    type: "text",
                                                    placeholder:
                                                      _vm.fcoM[
                                                        "common.lastName"
                                                      ],
                                                  },
                                                  domProps: {
                                                    value:
                                                      _vm.vehicleInfo.lastName,
                                                  },
                                                  on: {
                                                    input: function ($event) {
                                                      if (
                                                        $event.target.composing
                                                      )
                                                        return
                                                      _vm.$set(
                                                        _vm.vehicleInfo,
                                                        "lastName",
                                                        $event.target.value
                                                      )
                                                    },
                                                  },
                                                }),
                                                _vm._v(" "),
                                                errors[0]
                                                  ? _c(
                                                      "span",
                                                      {
                                                        staticClass: "error",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(errors[0])
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "row",
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "col-sm form-group",
                                  },
                                  [
                                    _c("ValidationProvider", {
                                      attrs: {
                                        tag: "div",
                                        name: _vm.fcoM[
                                          "common.address.street1"
                                        ],
                                        rules: {
                                          required: _vm.hasCustomerAddressInfo,
                                          max: 80,
                                          basicChars: true,
                                        },
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (_ref4) {
                                              var errors = _ref4.errors,
                                                classes = _ref4.classes
                                              return [
                                                _c("label", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.fcoM[
                                                        "common.address.street1"
                                                      ]
                                                    )
                                                  ),
                                                ]),
                                                _vm._v(" "),
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm.vehicleInfo.street1,
                                                      expression:
                                                        "vehicleInfo.street1",
                                                    },
                                                  ],
                                                  class: classes,
                                                  attrs: {
                                                    type: "text",
                                                    placeholder:
                                                      _vm.fcoM[
                                                        "common.address.street1"
                                                      ],
                                                  },
                                                  domProps: {
                                                    value:
                                                      _vm.vehicleInfo.street1,
                                                  },
                                                  on: {
                                                    input: function ($event) {
                                                      if (
                                                        $event.target.composing
                                                      )
                                                        return
                                                      _vm.$set(
                                                        _vm.vehicleInfo,
                                                        "street1",
                                                        $event.target.value
                                                      )
                                                    },
                                                  },
                                                }),
                                                _vm._v(" "),
                                                errors[0]
                                                  ? _c(
                                                      "span",
                                                      {
                                                        staticClass: "error",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(errors[0])
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "col-sm form-group",
                                  },
                                  [
                                    _c("ValidationProvider", {
                                      attrs: {
                                        tag: "div",
                                        name: _vm.fcoM[
                                          "common.address.street2"
                                        ],
                                        rules: {
                                          max: 80,
                                          basicChars: true,
                                        },
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (_ref5) {
                                              var errors = _ref5.errors,
                                                classes = _ref5.classes
                                              return [
                                                _c("label", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.fcoM[
                                                        "common.address.street2"
                                                      ]
                                                    )
                                                  ),
                                                ]),
                                                _vm._v(" "),
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm.vehicleInfo.street2,
                                                      expression:
                                                        "vehicleInfo.street2",
                                                    },
                                                  ],
                                                  class: classes,
                                                  attrs: {
                                                    type: "text",
                                                    placeholder:
                                                      _vm.fcoM[
                                                        "common.address.street2"
                                                      ],
                                                  },
                                                  domProps: {
                                                    value:
                                                      _vm.vehicleInfo.street2,
                                                  },
                                                  on: {
                                                    input: function ($event) {
                                                      if (
                                                        $event.target.composing
                                                      )
                                                        return
                                                      _vm.$set(
                                                        _vm.vehicleInfo,
                                                        "street2",
                                                        $event.target.value
                                                      )
                                                    },
                                                  },
                                                }),
                                                _vm._v(" "),
                                                errors[0]
                                                  ? _c(
                                                      "span",
                                                      {
                                                        staticClass: "error",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(errors[0])
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "row",
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "col-sm form-group",
                                  },
                                  [
                                    _c("ValidationProvider", {
                                      attrs: {
                                        tag: "div",
                                        name: _vm.fcoM["common.address.city"],
                                        rules: {
                                          required: _vm.hasCustomerAddressInfo,
                                          max: 40,
                                          basicChars: true,
                                        },
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (_ref6) {
                                              var errors = _ref6.errors,
                                                classes = _ref6.classes
                                              return [
                                                _c("label", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.fcoM[
                                                        "common.address.city"
                                                      ]
                                                    )
                                                  ),
                                                ]),
                                                _vm._v(" "),
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm.vehicleInfo.city,
                                                      expression:
                                                        "vehicleInfo.city",
                                                    },
                                                  ],
                                                  class: classes,
                                                  attrs: {
                                                    type: "text",
                                                    placeholder:
                                                      _vm.fcoM[
                                                        "common.address.city"
                                                      ],
                                                  },
                                                  domProps: {
                                                    value: _vm.vehicleInfo.city,
                                                  },
                                                  on: {
                                                    input: function ($event) {
                                                      if (
                                                        $event.target.composing
                                                      )
                                                        return
                                                      _vm.$set(
                                                        _vm.vehicleInfo,
                                                        "city",
                                                        $event.target.value
                                                      )
                                                    },
                                                  },
                                                }),
                                                _vm._v(" "),
                                                errors[0]
                                                  ? _c(
                                                      "span",
                                                      {
                                                        staticClass: "error",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(errors[0])
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "col-sm form-group",
                                  },
                                  [
                                    _c("ValidationProvider", {
                                      attrs: {
                                        tag: "div",
                                        name: _vm.fcoM["common.address.state"],
                                        rules: {
                                          required: _vm.hasCustomerAddressInfo,
                                        },
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (_ref7) {
                                              var errors = _ref7.errors,
                                                classes = _ref7.classes
                                              return [
                                                _c("label", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.fcoM[
                                                        "common.address.state"
                                                      ]
                                                    )
                                                  ),
                                                ]),
                                                _vm._v(" "),
                                                _c(
                                                  "select",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value:
                                                          _vm.vehicleInfo.state,
                                                        expression:
                                                          "vehicleInfo.state",
                                                      },
                                                    ],
                                                    class: [
                                                      "custom-select",
                                                      classes,
                                                    ],
                                                    on: {
                                                      change: function (
                                                        $event
                                                      ) {
                                                        var $$selectedVal =
                                                          Array.prototype.filter
                                                            .call(
                                                              $event.target
                                                                .options,
                                                              function (o) {
                                                                return o.selected
                                                              }
                                                            )
                                                            .map(function (o) {
                                                              var val =
                                                                "_value" in o
                                                                  ? o._value
                                                                  : o.value
                                                              return val
                                                            })
                                                        _vm.$set(
                                                          _vm.vehicleInfo,
                                                          "state",
                                                          $event.target.multiple
                                                            ? $$selectedVal
                                                            : $$selectedVal[0]
                                                        )
                                                      },
                                                    },
                                                  },
                                                  _vm._l(
                                                    _vm.usStates,
                                                    function (state) {
                                                      return _c(
                                                        "option",
                                                        {
                                                          key: `state${state.abbr}`,
                                                          domProps: {
                                                            value: state.abbr,
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(state.name)
                                                          ),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  0
                                                ),
                                                _vm._v(" "),
                                                errors[0]
                                                  ? _c(
                                                      "span",
                                                      {
                                                        staticClass: "error",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(errors[0])
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "col-sm",
                                  },
                                  [
                                    _c("ValidationProvider", {
                                      attrs: {
                                        tag: "div",
                                        name: _vm.fcoM["common.address.zip"],
                                        rules: {
                                          required: _vm.hasCustomerAddressInfo,
                                          max: 10,
                                          zipCode: true,
                                        },
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (_ref8) {
                                              var errors = _ref8.errors,
                                                classes = _ref8.classes
                                              return [
                                                _c("label", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.fcoM[
                                                        "common.address.zip"
                                                      ]
                                                    )
                                                  ),
                                                ]),
                                                _vm._v(" "),
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm.vehicleInfo.zip,
                                                      expression:
                                                        "vehicleInfo.zip",
                                                    },
                                                  ],
                                                  class: classes,
                                                  attrs: {
                                                    type: "text",
                                                    placeholder:
                                                      _vm.fcoM[
                                                        "common.address.zip"
                                                      ],
                                                  },
                                                  domProps: {
                                                    value: _vm.vehicleInfo.zip,
                                                  },
                                                  on: {
                                                    input: function ($event) {
                                                      if (
                                                        $event.target.composing
                                                      )
                                                        return
                                                      _vm.$set(
                                                        _vm.vehicleInfo,
                                                        "zip",
                                                        $event.target.value
                                                      )
                                                    },
                                                  },
                                                }),
                                                _vm._v(" "),
                                                errors[0]
                                                  ? _c(
                                                      "span",
                                                      {
                                                        staticClass: "error",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(errors[0])
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "row",
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "col-sm form-group",
                                  },
                                  [
                                    _c("ValidationProvider", {
                                      attrs: {
                                        tag: "div",
                                        name: _vm.fcoM["rs.quote.vm.phone"],
                                        rules: {
                                          usPhone: true,
                                          max: 20,
                                        },
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (_ref9) {
                                              var errors = _ref9.errors,
                                                classes = _ref9.classes
                                              return [
                                                _c("label", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.fcoM[
                                                        "rs.quote.vm.phone"
                                                      ]
                                                    )
                                                  ),
                                                ]),
                                                _vm._v(" "),
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm.vehicleInfo.phone,
                                                      expression:
                                                        "vehicleInfo.phone",
                                                    },
                                                  ],
                                                  class: classes,
                                                  attrs: {
                                                    type: "text",
                                                    placeholder:
                                                      _vm.fcoM[
                                                        "rs.quote.vm.phone"
                                                      ],
                                                  },
                                                  domProps: {
                                                    value:
                                                      _vm.vehicleInfo.phone,
                                                  },
                                                  on: {
                                                    input: function ($event) {
                                                      if (
                                                        $event.target.composing
                                                      )
                                                        return
                                                      _vm.$set(
                                                        _vm.vehicleInfo,
                                                        "phone",
                                                        $event.target.value
                                                      )
                                                    },
                                                  },
                                                }),
                                                _vm._v(" "),
                                                errors[0]
                                                  ? _c(
                                                      "span",
                                                      {
                                                        staticClass: "error",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(errors[0])
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "col-sm form-group",
                                  },
                                  [
                                    _c("ValidationProvider", {
                                      attrs: {
                                        tag: "div",
                                        name: _vm.fcoM["common.email"],
                                        rules: {
                                          email: true,
                                          max: 80,
                                        },
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (_ref10) {
                                              var errors = _ref10.errors,
                                                classes = _ref10.classes
                                              return [
                                                _c("label", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.fcoM["common.email"]
                                                    )
                                                  ),
                                                ]),
                                                _vm._v(" "),
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm.vehicleInfo.email,
                                                      expression:
                                                        "vehicleInfo.email",
                                                    },
                                                  ],
                                                  class: classes,
                                                  attrs: {
                                                    type: "text",
                                                    placeholder:
                                                      _vm.fcoM["common.email"],
                                                  },
                                                  domProps: {
                                                    value:
                                                      _vm.vehicleInfo.email,
                                                  },
                                                  on: {
                                                    input: function ($event) {
                                                      if (
                                                        $event.target.composing
                                                      )
                                                        return
                                                      _vm.$set(
                                                        _vm.vehicleInfo,
                                                        "email",
                                                        $event.target.value
                                                      )
                                                    },
                                                  },
                                                }),
                                                _vm._v(" "),
                                                errors[0]
                                                  ? _c(
                                                      "span",
                                                      {
                                                        staticClass: "error",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(errors[0])
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "col-lg",
                          },
                          [
                            _c(
                              "FcoTitleBar",
                              {
                                staticClass: "mb-2",
                                attrs: {
                                  "bg-color": "gray",
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.vehicleInfo.shopVehicleDescriptor)
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "form-group",
                              },
                              [
                                _c("ValidationProvider", {
                                  attrs: {
                                    tag: "div",
                                    name: _vm.fcoM["common.description"],
                                    rules: {
                                      basicChars: true,
                                      max: 255,
                                    },
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (_ref11) {
                                          var errors = _ref11.errors,
                                            classes = _ref11.classes
                                          return [
                                            _c("label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.fcoM["common.description"]
                                                )
                                              ),
                                            ]),
                                            _vm._v(" "),
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.vehicleInfo
                                                      .vehicleDescription,
                                                  expression:
                                                    "vehicleInfo.vehicleDescription",
                                                },
                                              ],
                                              class: classes,
                                              attrs: {
                                                type: "text",
                                                placeholder:
                                                  _vm.fcoM[
                                                    "common.description"
                                                  ],
                                              },
                                              domProps: {
                                                value:
                                                  _vm.vehicleInfo
                                                    .vehicleDescription,
                                              },
                                              on: {
                                                input: function ($event) {
                                                  if ($event.target.composing)
                                                    return
                                                  _vm.$set(
                                                    _vm.vehicleInfo,
                                                    "vehicleDescription",
                                                    $event.target.value
                                                  )
                                                },
                                              },
                                            }),
                                            _vm._v(" "),
                                            errors[0]
                                              ? _c(
                                                  "span",
                                                  {
                                                    staticClass: "error",
                                                  },
                                                  [_vm._v(_vm._s(errors[0]))]
                                                )
                                              : _vm._e(),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "form-group",
                              },
                              [
                                _c("label", [
                                  _vm._v(
                                    _vm._s(_vm.fcoM["common.vehicleAttributes"])
                                  ),
                                ]),
                                _vm._v(" "),
                                _vm.vehicleAttributes.length === 0
                                  ? _c("div", [_vm._v("-")])
                                  : _c(
                                      "div",
                                      [
                                        _vm._l(
                                          _vm.vehicleAttributes.slice(0, 2),
                                          function (attribute) {
                                            return _c("VehicleAttribute", {
                                              key: attribute.attributeId,
                                              attrs: {
                                                attribute: attribute,
                                              },
                                              on: {
                                                remove: function ($event) {
                                                  return _vm.removeAttribute(
                                                    attribute.attributeId
                                                  )
                                                },
                                              },
                                            })
                                          }
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "fco-collapse",
                                          {
                                            attrs: {
                                              show: _vm.showAllAttributes,
                                            },
                                          },
                                          _vm._l(
                                            _vm.vehicleAttributes.slice(2),
                                            function (attribute) {
                                              return _c("VehicleAttribute", {
                                                key: attribute.attributeId,
                                                attrs: {
                                                  attribute: attribute,
                                                },
                                                on: {
                                                  remove: function ($event) {
                                                    return _vm.removeAttribute(
                                                      attribute.attributeId
                                                    )
                                                  },
                                                },
                                              })
                                            }
                                          ),
                                          1
                                        ),
                                      ],
                                      2
                                    ),
                                _vm._v(" "),
                                _vm.vehicleAttributes.length > 2
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "mt-2",
                                      },
                                      [
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              href: "javascript:void(0)",
                                            },
                                            on: {
                                              click: function ($event) {
                                                _vm.showAllAttributes =
                                                  !_vm.showAllAttributes
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                " +
                                                _vm._s(
                                                  _vm.showAllAttributes
                                                    ? _vm.fcoM["common.hide"]
                                                    : _vm.fcoM[
                                                        "common.link.showAll"
                                                      ]
                                                ) +
                                                "\n                            "
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "row",
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "col-sm form-group",
                                  },
                                  [
                                    _c("ValidationProvider", {
                                      attrs: {
                                        tag: "div",
                                        name: _vm.fcoM["common.odometer"],
                                        rules: "numberWithCommas",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (_ref12) {
                                              var errors = _ref12.errors,
                                                classes = _ref12.classes
                                              return [
                                                _c("label", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.fcoM[
                                                        "common.odometer"
                                                      ]
                                                    )
                                                  ),
                                                ]),
                                                _vm._v(" "),
                                                _c("input", {
                                                  class: classes,
                                                  attrs: {
                                                    type: "number",
                                                    placeholder:
                                                      _vm.fcoM[
                                                        "common.odometer"
                                                      ],
                                                  },
                                                  domProps: {
                                                    value:
                                                      _vm.vehicleInfo
                                                        .vehicleMileage,
                                                  },
                                                  on: {
                                                    input:
                                                      _vm.handleMileageInput,
                                                  },
                                                }),
                                                _vm._v(" "),
                                                errors[0]
                                                  ? _c(
                                                      "span",
                                                      {
                                                        staticClass: "error",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(errors[0])
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "col-sm form-group",
                                  },
                                  [
                                    _c("ValidationProvider", {
                                      attrs: {
                                        tag: "div",
                                        name: _vm.fcoM["common.licensePlate"],
                                        rules: {
                                          basicChars: true,
                                          max: 10,
                                        },
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (_ref13) {
                                              var errors = _ref13.errors,
                                                classes = _ref13.classes
                                              return [
                                                _c("label", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.fcoM[
                                                        "common.licensePlate"
                                                      ]
                                                    )
                                                  ),
                                                ]),
                                                _vm._v(" "),
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm.vehicleInfo
                                                          .vehicleLicensePlate,
                                                      expression:
                                                        "vehicleInfo.vehicleLicensePlate",
                                                    },
                                                  ],
                                                  class: classes,
                                                  attrs: {
                                                    type: "text",
                                                    placeholder:
                                                      _vm.fcoM[
                                                        "common.licensePlate"
                                                      ],
                                                  },
                                                  domProps: {
                                                    value:
                                                      _vm.vehicleInfo
                                                        .vehicleLicensePlate,
                                                  },
                                                  on: {
                                                    input: function ($event) {
                                                      if (
                                                        $event.target.composing
                                                      )
                                                        return
                                                      _vm.$set(
                                                        _vm.vehicleInfo,
                                                        "vehicleLicensePlate",
                                                        $event.target.value
                                                      )
                                                    },
                                                  },
                                                }),
                                                _vm._v(" "),
                                                errors[0]
                                                  ? _c(
                                                      "span",
                                                      {
                                                        staticClass: "error",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(errors[0])
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "row",
                              },
                              [
                                (_vm$currentShop = _vm.currentShop) !== null &&
                                _vm$currentShop !== void 0 &&
                                _vm$currentShop.vehicleFleetIdVisible
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "col-sm",
                                      },
                                      [
                                        _c("ValidationProvider", {
                                          attrs: {
                                            tag: "div",
                                            name: _vm.fcoM["common.fleetId"],
                                            rules: {
                                              basicChars: true,
                                              max: 10,
                                            },
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function (_ref14) {
                                                  var errors = _ref14.errors,
                                                    classes = _ref14.classes
                                                  return [
                                                    _c("label", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.fcoM[
                                                            "common.fleetId"
                                                          ]
                                                        )
                                                      ),
                                                    ]),
                                                    _vm._v(" "),
                                                    _c("input", {
                                                      directives: [
                                                        {
                                                          name: "model",
                                                          rawName: "v-model",
                                                          value:
                                                            _vm.vehicleInfo
                                                              .vehicleFleetId,
                                                          expression:
                                                            "vehicleInfo.vehicleFleetId",
                                                        },
                                                      ],
                                                      class: classes,
                                                      attrs: {
                                                        type: "text",
                                                        placeholder:
                                                          _vm.fcoM[
                                                            "common.fleetId"
                                                          ],
                                                      },
                                                      domProps: {
                                                        value:
                                                          _vm.vehicleInfo
                                                            .vehicleFleetId,
                                                      },
                                                      on: {
                                                        input: function (
                                                          $event
                                                        ) {
                                                          if (
                                                            $event.target
                                                              .composing
                                                          )
                                                            return
                                                          _vm.$set(
                                                            _vm.vehicleInfo,
                                                            "vehicleFleetId",
                                                            $event.target.value
                                                          )
                                                        },
                                                      },
                                                    }),
                                                    _vm._v(" "),
                                                    errors[0]
                                                      ? _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "error",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(errors[0])
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                (_vm$currentShop2 = _vm.currentShop) !== null &&
                                _vm$currentShop2 !== void 0 &&
                                _vm$currentShop2.garageBayIdVisible
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "col-sm form-group",
                                      },
                                      [
                                        _c("ValidationProvider", {
                                          attrs: {
                                            tag: "div",
                                            name: _vm.fcoM[
                                              "common.garageBayId"
                                            ],
                                            rules: {
                                              basicChars: true,
                                              max: 4,
                                            },
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function (_ref15) {
                                                  var errors = _ref15.errors,
                                                    classes = _ref15.classes
                                                  return [
                                                    _c("label", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.fcoM[
                                                            "common.garageBayId"
                                                          ]
                                                        )
                                                      ),
                                                    ]),
                                                    _vm._v(" "),
                                                    _c("input", {
                                                      directives: [
                                                        {
                                                          name: "model",
                                                          rawName: "v-model",
                                                          value:
                                                            _vm.vehicleInfo
                                                              .vehicleGarageBay,
                                                          expression:
                                                            "vehicleInfo.vehicleGarageBay",
                                                        },
                                                      ],
                                                      class: classes,
                                                      attrs: {
                                                        type: "text",
                                                        placeholder:
                                                          _vm.fcoM[
                                                            "common.garageBayId"
                                                          ],
                                                      },
                                                      domProps: {
                                                        value:
                                                          _vm.vehicleInfo
                                                            .vehicleGarageBay,
                                                      },
                                                      on: {
                                                        input: function (
                                                          $event
                                                        ) {
                                                          if (
                                                            $event.target
                                                              .composing
                                                          )
                                                            return
                                                          _vm.$set(
                                                            _vm.vehicleInfo,
                                                            "vehicleGarageBay",
                                                            $event.target.value
                                                          )
                                                        },
                                                      },
                                                    }),
                                                    _vm._v(" "),
                                                    errors[0]
                                                      ? _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "error",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(errors[0])
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "row",
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "col-sm form-group",
                                  },
                                  [
                                    _c("ValidationProvider", {
                                      attrs: {
                                        tag: "div",
                                        name: _vm.fcoM["rs.quote.vm.symptoms"],
                                        rules: {
                                          basicCharsMultiline: true,
                                          max: 1020,
                                        },
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (_ref16) {
                                              var errors = _ref16.errors,
                                                classes = _ref16.classes
                                              return [
                                                _c("label", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.fcoM[
                                                        "rs.quote.vm.symptoms"
                                                      ]
                                                    )
                                                  ),
                                                ]),
                                                _vm._v(" "),
                                                _c("textarea", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm.vehicleInfo
                                                          .customerComplaint,
                                                      expression:
                                                        "vehicleInfo.customerComplaint",
                                                    },
                                                  ],
                                                  class: classes,
                                                  attrs: {
                                                    placeholder:
                                                      _vm.fcoM[
                                                        "rs.quote.vm.symptoms"
                                                      ],
                                                  },
                                                  domProps: {
                                                    value:
                                                      _vm.vehicleInfo
                                                        .customerComplaint,
                                                  },
                                                  on: {
                                                    input: function ($event) {
                                                      if (
                                                        $event.target.composing
                                                      )
                                                        return
                                                      _vm.$set(
                                                        _vm.vehicleInfo,
                                                        "customerComplaint",
                                                        $event.target.value
                                                      )
                                                    },
                                                  },
                                                }),
                                                _vm._v(" "),
                                                errors[0]
                                                  ? _c(
                                                      "span",
                                                      {
                                                        staticClass: "error",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(errors[0])
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "col-sm form-group",
                                  },
                                  [
                                    _c("ValidationProvider", {
                                      attrs: {
                                        tag: "div",
                                        name: _vm.fcoM["common.notes"],
                                        rules: {
                                          basicCharsMultiline: true,
                                          max: 1020,
                                        },
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (_ref17) {
                                              var errors = _ref17.errors,
                                                classes = _ref17.classes
                                              return [
                                                _c("label", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.fcoM["common.notes"]
                                                    )
                                                  ),
                                                ]),
                                                _vm._v(" "),
                                                _c("textarea", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm.vehicleInfo
                                                          .vehicleNotes,
                                                      expression:
                                                        "vehicleInfo.vehicleNotes",
                                                    },
                                                  ],
                                                  class: classes,
                                                  attrs: {
                                                    placeholder:
                                                      _vm.fcoM["common.notes"],
                                                  },
                                                  domProps: {
                                                    value:
                                                      _vm.vehicleInfo
                                                        .vehicleNotes,
                                                  },
                                                  on: {
                                                    input: function ($event) {
                                                      if (
                                                        $event.target.composing
                                                      )
                                                        return
                                                      _vm.$set(
                                                        _vm.vehicleInfo,
                                                        "vehicleNotes",
                                                        $event.target.value
                                                      )
                                                    },
                                                  },
                                                }),
                                                _vm._v(" "),
                                                errors[0]
                                                  ? _c(
                                                      "span",
                                                      {
                                                        staticClass: "error",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(errors[0])
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        ref: "submit",
                        staticClass: "button d-none",
                        attrs: {
                          type: "submit",
                        },
                      },
                      [_vm._v(_vm._s(_vm.fcoM["common.submit"]))]
                    ),
                    _vm._v(" "),
                    _vm.isLoading
                      ? _c("FcoLoadingOverlay", {
                          staticClass: "position-absolute",
                        })
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "secondary",
                    attrs: {
                      slot: "footer",
                      type: "button",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.$emit("close")
                      },
                    },
                    slot: "footer",
                  },
                  [_vm._v(_vm._s(_vm.fcoM["common.cancel"]))]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "button",
                    attrs: {
                      slot: "footer",
                      type: "button",
                      disabled: !_vm.hasCustomerName,
                    },
                    on: {
                      click: function ($event) {
                        validate().then(_vm.saveVehicleInfo)
                      },
                    },
                    slot: "footer",
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.fcoM["common.submit"]) +
                        "\n        "
                    ),
                  ]
                ),
              ]
            ),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }