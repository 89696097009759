var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    _vm.isSPA ? "RouterLink" : "a",
    _vm._b(
      {
        tag: "component",
      },
      "component",
      _vm.attrs,
      false
    ),
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }