var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    {
      staticClass: "go-to-quote-container",
    },
    [
      _vm.emptyQuote
        ? _c(
            "div",
            {
              staticClass: "no-quote d-none d-sm-flex",
            },
            [
              _c(
                "a",
                {
                  staticClass: "gtm-miniquote-quote-empty",
                  attrs: {
                    href: "javascript:void(0)",
                    "data-qa": "go-to-quote-button",
                  },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.handleGoQuoteClick.apply(null, arguments)
                    },
                  },
                },
                [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.fcoM["common.goToQuote"]) +
                      "\n            "
                  ),
                  _c("Icon", {
                    staticClass: "text-md",
                    attrs: {
                      name: "chevron-right",
                    },
                  }),
                ],
                1
              ),
            ]
          )
        : _c(
            "div",
            {
              staticClass: "quote-wrapper d-none d-sm-flex",
            },
            [
              _c(
                "strong",
                {
                  class: [
                    "items-cost",
                    {
                      "dropdown-activated": _vm.expandMiniQuote,
                    },
                  ],
                  attrs: {
                    "data-qa": "mini-quote-toggle",
                  },
                  on: {
                    click: _vm.toggleMiniQuote,
                  },
                },
                [
                  _c("span", [
                    _vm._v(
                      "$" +
                        _vm._s(_vm.subtotal) +
                        " (" +
                        _vm._s(_vm.quoteDetails.totalItems) +
                        ")"
                    ),
                  ]),
                  _vm._v(" "),
                  _c("Icon", {
                    staticClass: "clickable text-md",
                    attrs: {
                      name: _vm.miniQuoteCaretName,
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "FcoLink",
                {
                  staticClass: "button d-inline mb-0",
                  attrs: {
                    to: `/quote/details/${_vm.currentQuoteId}`,
                    href: _vm.fcoUrl(
                      `/worksheet/${_vm.currentQuoteId}/show.html`
                    ),
                    "data-qa": "go-to-quote-button",
                  },
                },
                [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.fcoM["common.goToQuote"]) +
                      "\n            "
                  ),
                  _c("Icon", {
                    staticClass: "text-md",
                    attrs: {
                      name: "chevron-right",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _vm.expandMiniQuote
                ? _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "on-clickaway",
                          rawName: "v-on-clickaway",
                          value: _vm.toggleMiniQuote,
                          expression: "toggleMiniQuote",
                        },
                      ],
                      staticClass: "quote-items-dropdown",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "dropdown-content",
                        },
                        [
                          _c(
                            "ul",
                            {
                              staticClass: "items-in-quote",
                            },
                            _vm._l(_vm.quoteProducts, function (product) {
                              return _c(
                                "li",
                                {
                                  key: product.miniQuoteKey,
                                  staticClass: "d-flex p-2",
                                },
                                [
                                  _c("div", [
                                    _c(
                                      "span",
                                      {
                                        attrs: {
                                          "data-qa":
                                            "mini-quote-remove-product",
                                        },
                                        on: {
                                          click: function ($event) {
                                            $event.preventDefault()
                                            return _vm.removeProduct(product)
                                          },
                                        },
                                      },
                                      [
                                        _c("Icon", {
                                          staticClass:
                                            "clickable red text-md mr-1",
                                          attrs: {
                                            name: "times",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "flex-grow-1",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "part-name",
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(product.itemDescription)
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "part-details",
                                        },
                                        [
                                          _vm._v(
                                            "\n                                " +
                                              _vm._s(
                                                _vm.fcoM[
                                                  "rs.infoBar.quantityLabel"
                                                ]
                                              ) +
                                              "\n                                " +
                                              _vm._s(product.itemQuantity) +
                                              "\n                                "
                                          ),
                                          _c("MiniQuoteProductPrice", {
                                            attrs: {
                                              product: product,
                                              "hide-costs": _vm.isCostHidden,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            }),
                            0
                          ),
                          _vm._v(" "),
                          _c("div", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.showQuoteMask,
                                expression: "showQuoteMask",
                              },
                            ],
                            staticClass: "quote-mask",
                          }),
                        ]
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "mobile-quote-container d-sm-none",
        },
        [
          _c(
            "a",
            {
              class: [
                "button",
                "gtm-miniquote-quote",
                {
                  "disabled-btn gtm-quote-empty": _vm.emptyQuote,
                },
              ],
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.handleGoQuoteClick.apply(null, arguments)
                },
              },
            },
            [_vm._v("$")]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }