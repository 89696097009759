var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c("footer", [
    _c(
      "div",
      {
        staticClass: "content py-5",
      },
      [
        _c(
          "div",
          {
            staticClass: "footer-grid d-print-none mb-5",
          },
          [
            _vm._m(0),
            _vm._v(" "),
            _c(
              "nav",
              [
                _c("DotCmsMenu", {
                  staticClass: "footer-nav",
                  attrs: {
                    name: "site-footer",
                    placeholderMaxWidth: 150,
                    placeholderMinWidth: 100,
                  },
                }),
                _vm._v(" "),
                _c(
                  "ul",
                  {
                    staticClass: "footer-nav",
                  },
                  [
                    _vm.userCanViewStatements
                      ? _c(
                          "li",
                          [
                            _c(
                              "FcoLink",
                              {
                                staticClass: "qaa-footer-billing-statements",
                                attrs: {
                                  to: "/admin/statements",
                                  href: _vm.fcoUrl(
                                    "/statementlookup/statementview.html"
                                  ),
                                },
                              },
                              [
                                _vm.isEarnbackEligible
                                  ? _c("span", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.fcoM[
                                              "common.statementsAndEarnbacks"
                                            ]
                                          )
                                      ),
                                    ])
                                  : _c("span", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.fcoM["common.billingStatements"]
                                          )
                                      ),
                                    ]),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.isAuthorizedUser
                      ? [
                          _c(
                            "li",
                            [
                              _c(
                                "DotCmsLink",
                                {
                                  staticClass: "qaa-footer-drop-box-slip",
                                  attrs: {
                                    "file-name": "first-call-drop-box-slip.pdf",
                                    target: "_blank",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(_vm.fcoM["rs.home.dropBoxSlip"]) +
                                      "\n                            "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "li",
                            [
                              _c(
                                "DotCmsLink",
                                {
                                  staticClass:
                                    "qaa-footer-vehicle-inspection-form",
                                  attrs: {
                                    "file-name": "FCO-INSPECTION_FORM.pdf",
                                    target: "_blank",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(
                                        _vm.fcoM[
                                          "rs.home.vehicleInspectionForm"
                                        ]
                                      ) +
                                      "\n                            "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.userCanViewLaborClaims
                            ? _c(
                                "li",
                                [
                                  _c(
                                    "FcoLink",
                                    {
                                      staticClass: "qaa-footer-labor-claims",
                                      attrs: {
                                        to: "/admin/labor-claims",
                                        href: _vm.fcoUrl("/admin/laborClaims/"),
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                                " +
                                          _vm._s(
                                            _vm.fcoM["common.laborClaims"]
                                          ) +
                                          "\n                            "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]
                      : _vm._e(),
                  ],
                  2
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "text-right",
              },
              [
                _c("LanguageSelectButton", {
                  attrs: {
                    "button-class": "footer-language-select",
                    "label-class": "footer-language-select-label",
                  },
                }),
              ],
              1
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "text-xs footer-legal",
          },
          [
            _c(
              "div",
              {
                staticClass: "med-gray mb-3",
              },
              [
                _vm._v(_vm._s(_vm.copyrightString)),
                _c("br"),
                _vm._v(_vm._s(_vm.versionString)),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "footer-legal-nav d-print-none",
              },
              [
                _c(
                  "FcoLink",
                  {
                    staticClass: "qaa-footer-terms-conditions",
                    attrs: {
                      to: "/terms",
                      href: _vm.fcoUrl("/legal/terms/index.html"),
                    },
                  },
                  [_vm._v(_vm._s(_vm.fcoM["common.termsAndConditions"]))]
                ),
                _vm._v(" "),
                _c(
                  "FcoLink",
                  {
                    staticClass: "qaa-footer-privacy-policy",
                    attrs: {
                      to: "/privacy",
                      href: _vm.fcoUrl("/legal/policy/index.html"),
                    },
                  },
                  [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.fcoM["common.privacyPolicy"]) +
                        "\n                "
                    ),
                  ]
                ),
              ],
              1
            ),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h

    return _c(
      "div",
      {
        staticClass: "footer-logo-container",
      },
      [
        _c(
          "picture",
          {
            staticClass: "footer-logo",
          },
          [
            _c("source", {
              attrs: {
                type: "image/webp",
                srcset:
                  "/FirstCallOnline/images/fco-logo-white-208.webp" +
                  ", " +
                  "/FirstCallOnline/images/fco-logo-white-416.webp" +
                  " 2x",
              },
            }),
            _vm._v(" "),
            _c("source", {
              attrs: {
                type: "image/png",
                srcset:
                  "/FirstCallOnline/images/fco-logo-white-208.png" +
                  ", " +
                  "/FirstCallOnline/images/fco-logo-white-416.png" +
                  " 2x",
              },
            }),
            _vm._v(" "),
            _c("source", {
              attrs: {
                type: "image/png",
                srcset: "/FirstCallOnline/images/fco-logo-white-208.png",
              },
            }),
            _vm._v(" "),
            _c("img", {
              staticClass: "footer-logo",
              attrs: {
                src: "/FirstCallOnline/images/fco-logo-white.png",
              },
            }),
          ]
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }