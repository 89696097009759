var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return !!_vm.price
    ? _c("span", [
        _vm._v("\n    –\n    "),
        _vm._v(" "),
        _c(
          "span",
          {
            class: {
              "text-strike": _vm.isPartOfKit,
            },
          },
          [_vm._v("$" + _vm._s(_vm.price))]
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }