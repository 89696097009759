var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    {
      staticClass: "content d-none d-md-block",
    },
    [
      _c(
        "h2",
        {
          staticClass: "sr-only",
        },
        [_vm._v(_vm._s(_vm.fcoM["rs.home.marketingBoxesHeader"]))]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "row",
        },
        _vm._l(_vm.marketingBoxes, function (marketingBox, index) {
          return _c("BannerZone", {
            key: `marketingBox${index}`,
            class: ["col", `gtm-login-marketing-${index + 1}`],
            attrs: {
              name: marketingBox,
              "aspect-ratio": "248 / 269",
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "beforeImages",
                  fn: function (_ref) {
                    var _banners$, _banners$2
                    var banners = _ref.banners
                    return [
                      _c(
                        "a",
                        {
                          staticClass: "text-decoration-none",
                          attrs: {
                            href:
                              banners === null || banners === void 0
                                ? void 0
                                : (_banners$ = banners[0]) === null ||
                                  _banners$ === void 0
                                ? void 0
                                : _banners$.linkUrl,
                          },
                        },
                        [
                          _c(
                            "FcoTitleBar",
                            {
                              staticClass: "text-center font-oswald",
                            },
                            [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    banners === null || banners === void 0
                                      ? void 0
                                      : (_banners$2 = banners[0]) === null ||
                                        _banners$2 === void 0
                                      ? void 0
                                      : _banners$2.description
                                  )
                                ),
                              ]),
                              _vm._v(" "),
                              _c("Icon", {
                                attrs: {
                                  name: "chevron-right",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ],
              null,
              true
            ),
          })
        }),
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }