var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _vm.asset.name && _vm.cmsData.url
    ? _c(
        "div",
        {
          attrs: {
            "data-qa": "banner-image",
          },
        },
        [
          _c(
            _vm.bannerImage.linkUrl ? "a" : "span",
            {
              tag: "component",
              attrs: {
                href: _vm.bannerImage.linkUrl,
              },
            },
            [
              _c("DotCmsImage", {
                attrs: {
                  src: _vm.src,
                  alt: _vm.alt,
                  width: _vm.width,
                  height: _vm.height,
                  constrainWidth: _vm.constrainWidth,
                  constrainHeight: _vm.constrainHeight,
                },
              }),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }