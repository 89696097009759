import { mapGetters, mapState } from 'vuex';

export default {
    computed: {
        ...mapState(['requests', 'features', 'featuresForStore']),
        ...mapGetters(['isFeatureDisabled', 'isAuthorizedUser']),
        isEntSearchEnabled() {
            return !this.isFeatureDisabled('entsearch');
        },
    },
    methods: {
        async loadFeatureFlags() {
            const requests = ['getFeatures'];

            if (this.isAuthorizedUser) {
                requests.push('getFeaturesForStore');
            }

            const requestPromise = this.$store.dispatch('requestIfIdle', requests);

            // We may have cached feature flags already loaded. In that case, we don't need to await the request to get them.
            // Any updates to feature flags will be loaded in the background, and the cache will be updated accordingly.
            if (this.features.length && (!this.isAuthorizedUser || this.featuresForStore.length)) return;

            await requestPromise;
        },
    },
    mounted() {
        this.loadFeatureFlags();
    },
};
