export default {
    methods: {
        createOneShotMessage(message, { messageType = 'error', autoClose = true, displayDuration = 5000, escape = true } = {}) {
            const options = { messageType, autoClose, displayDuration, escape };
            let oneShotMessages = JSON.parse(localStorage.getItem('oneShotMessages'));
            if (oneShotMessages) {
                oneShotMessages.push({ message, options });
            } else {
                oneShotMessages = [{ message, options }];
            }
            localStorage.setItem('oneShotMessages', JSON.stringify(oneShotMessages));
        },
        async getOneShotMessages() {
            const oneShotMessages = JSON.parse(localStorage.getItem('oneShotMessages'));
            if (oneShotMessages?.length) oneShotMessages.forEach(({ message, options }) => this.$fcoToast.show(message, options));
            localStorage.removeItem('oneShotMessages');
        },
    },
};
