<template>
    <component :is="isSPA ? 'RouterLink' : 'a'" v-bind="attrs"><slot /></component>
</template>

<script>
// Convenience component during the MPA to SPA transition.
// Allows you to specify both a :to and :href prop so the link will point to the correct place depending on the scenario
export default {
    name: 'FcoLink',
    props: {
        to: {
            type: [String, Object],
            required: true,
        },
        href: {
            type: String,
            required: true,
        },
    },
    computed: {
        attrs() {
            if (!this.isSPA) return { href: this.href };
            return { to: this.to };
        },
    },
};
</script>
