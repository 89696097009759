import { Locale } from './user';

export const DotCMSLanguageId = {
    EN: '1',
    ES: __FCO_DOTCMS_LANGUAGE_ID_SPANISH__,
};

export const dotCMSLanguageIdByLocale = {
    [Locale.EN]: DotCMSLanguageId.EN,
    [Locale.ES]: DotCMSLanguageId.ES,
};
