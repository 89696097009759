var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c("VueSkeletonLoader", {
    attrs: {
      width: _vm.finalWidth,
      height: "1.5em",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }