export const PrintQuoteItemType = {
    PART: 'PART',
    KIT_SUMMARY: 'KIT_SUMMARY',
    WARNING: 'WARNING',
};

export const QuoteItemTypes = {
    LABOR: 'LABOR',
    OTHER: 'OTHER',
};

export const prop65URL = 'www.p65warnings.ca.gov';
