import { mapGetters } from 'vuex';
import { fcoUrl } from 'fcoModules/utilities';
import axios from 'axios';

export default {
    computed: {
        ...mapGetters(['isAuthorizedUser']),
    },
    watch: {
        isAuthorizedUser: {
            immediate: true,
            async handler() {
                if (!this.isAuthorizedUser) return;
                try {
                    const { data: hasDealerControlOrIsDemo } = await axios.get(fcoUrl('/current/hasDealerControlOrIsDemo'));
                    if (!hasDealerControlOrIsDemo) {
                        this.$fcoToast.error(this.fcoM['oauth.noDealerControl'], { autoClose: false });
                    }
                } catch {
                    this.$fcoToast.error(this.fcoM['oauth.noDealerControlError']);
                }
            },
        },
    },
};
