import axios from 'axios';
import { fcoUrl } from '../../fcoModules/utilities';

const makeAttribute = ({ attributeId, valueId, partTypeId = '', updateAttributeQuestion = true }) => ({
    attributeId,
    valueId,
    partTypeId,
    updateAttributeQuestion,
});

export const getRetainQuestions = (payload) => axios.post(fcoUrl('/attribute/rest/v2/questions/retain'), payload);

export const getNonRetainQuestions = (payload) => axios.post(fcoUrl('/attribute/rest/v2/questions/nonretain'), payload);

export const retainQuestionAnswer = (payload) => axios.post(fcoUrl('/attribute/rest/v2/questions/retain/answer'), payload);

export const remove = ({ attributeId, valueId, partTypeId, updateAttributeQuestion = false }) =>
    axios.post(fcoUrl('/attribute/rest/remove'), makeAttribute({ attributeId, valueId, partTypeId, updateAttributeQuestion }));

export const add = ({ attributeId, valueId, partTypeId }) =>
    axios.post(fcoUrl('/attribute/rest/add'), makeAttribute({ attributeId, valueId, partTypeId, updateAttributeQuestion: false }));
