/* eslint no-shadow: ['error', { 'allow': ['state', 'getters'] }] */
import * as stockOrderService from '../../services/stockOrderService';

let quickAddRequestTimer;
let stockOrdersCachedRequest;

export const state = {
    requestedQuickAddProductIds: new Set(), // list of product IDs for stock orders that have been requested
    newQuickAddLineItems: [], // this is a list of objects ({ line, item }) for stock orders confirmed to have NOT been requested yet
    quickAddStockOrders: {}, // quick add stock order details mapped by product IDs
    stockOrders: [],
};

const createQuickAddProductId = ({ line, item }) => `${line}${item}`;

export const mutations = {
    addQuickAddProductId(state, lineItem) {
        const productId = createQuickAddProductId(lineItem);

        if (!state.requestedQuickAddProductIds.has(productId)) {
            state.newQuickAddLineItems.push(lineItem);
            state.requestedQuickAddProductIds.add(productId);
        }
    },
    clearNewQuickAddLineItems(state) {
        state.newQuickAddLineItems = state.newQuickAddLineItems.filter((lineItem) => !state.quickAddStockOrders[createQuickAddProductId(lineItem)]);
    },
    setQuickAddStockOrders(state, quickAddStockOrders) {
        state.quickAddStockOrders = { ...state.quickAddStockOrders, ...quickAddStockOrders };
    },
    updateQuickAddStockOrder(state, { line, item, ...quickAddStockOrder }) {
        const productId = createQuickAddProductId({ line, item });
        const quickAddStockOrders = state.quickAddStockOrders[productId];

        if (!quickAddStockOrders) {
            state.quickAddStockOrders[productId] = [quickAddStockOrder];
        } else {
            const stockOrderIndex = quickAddStockOrders.findIndex(({ id }) => id === quickAddStockOrder.id);
            if (stockOrderIndex > -1) {
                quickAddStockOrders.splice(stockOrderIndex, 1, quickAddStockOrder);
            } else {
                quickAddStockOrders.push(quickAddStockOrder);
            }
        }
    },
    removeQuickAddStockOrder(state, { line, item, id }) {
        const productId = createQuickAddProductId({ line, item });
        const quickAddStockOrders = state.quickAddStockOrders[productId];

        if (!quickAddStockOrders) return;

        const stockOrderIndex = quickAddStockOrders.findIndex((quickAddStockOrder) => id === quickAddStockOrder.id);

        if (stockOrderIndex === -1) return;

        quickAddStockOrders.splice(stockOrderIndex, 1);
    },
    setStockOrders(state, stockOrders = []) {
        state.stockOrders = stockOrders;
    },
    addStockOrder(state, stockOrder) {
        state.stockOrders.splice(0, 0, stockOrder);
    },
};

export const actions = {
    async getQuickAddStockOrders({ commit, state }, { line, item } = {}) {
        if (!line || !item) return;

        commit('addQuickAddProductId', { line, item });

        clearTimeout(quickAddRequestTimer);

        quickAddRequestTimer = setTimeout(async () => {
            if (state.newQuickAddLineItems.length === 0) return;

            const { data: quickAddStockOrders } = await stockOrderService.getStockOrdersContainingLineItems(state.newQuickAddLineItems);

            commit('setQuickAddStockOrders', quickAddStockOrders);
            commit('clearNewQuickAddLineItems');
        }, 200);
    },
    async getStockOrders({ commit, state }, { size = 500 } = {}) {
        const stockOrdersCount = state.stockOrders.length;

        if (stockOrdersCachedRequest || stockOrdersCount >= size) {
            await stockOrdersCachedRequest;
            return state.stockOrders;
        }

        stockOrdersCachedRequest = stockOrderService.getStockOrders({ size });
        const { data: stockOrders } = await stockOrdersCachedRequest;

        commit('setStockOrders', stockOrders);
        return state.stockOrders;
    },
    async createStockOrderWithProduct({ commit }, stockOrderWithProduct) {
        const { line, item, description, quantity } = stockOrderWithProduct;
        const { data: stockOrder } = await stockOrderService.createStockOrderWithProduct(stockOrderWithProduct);

        commit('addStockOrder', stockOrder);
        commit('updateQuickAddStockOrder', {
            id: stockOrder.id,
            line,
            item,
            description,
            quantity,
            stockOrderDetailId: stockOrder.stockOrderDetails[0].id,
        });
    },
    async updateStockOrderProduct({ commit }, { stockOrderId, ...stockOrderProduct }) {
        const { data: stockOrderProductResponse } = await stockOrderService.updateStockOrderProduct(stockOrderId, stockOrderProduct);

        commit('updateQuickAddStockOrder', {
            id: stockOrderId,
            ...stockOrderProduct,
            stockOrderDetailId: stockOrderProductResponse.id,
        });
    },
    async deleteStockOrderProduct({ commit, getters }, { stockOrderId, line, item }) {
        const quickAddStockOrder = getters.quickAddStockOrders({ line, item }).find(({ id }) => id === stockOrderId);

        if (!quickAddStockOrder) return;

        await stockOrderService.deleteStockOrderDetail(stockOrderId, quickAddStockOrder.stockOrderDetailId);
        commit('removeQuickAddStockOrder', { line, item, id: stockOrderId });
    },
};

export const getters = {
    quickAddStockOrders:
        (state) =>
        ({ line, item }) =>
            state.quickAddStockOrders[createQuickAddProductId({ line, item })] || [],
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
