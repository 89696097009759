<template>
    <ul>
        <template v-if="isLoading">
            <li v-for="n in numberOfPlaceholders" :key="`${name}_loader_${n}`">
                <SkeletonText class="mw-100" :maxWidth="placeholderMaxWidth" :minWidth="placeholderMinWidth" />
            </li>
        </template>
        <template v-else>
            <li v-for="(menuItem, index) in menu.menuItems" :key="`${menuItem.title}_${index}`">
                <DotCmsLink v-if="menuItem.linkToFile" :fileName="menuItem.linkToFile.fileName" :label="menuItem.title" />
                <a v-else :href="menuItem.linkUrl">{{ menuItem.title }}</a>

                <DotCmsImage v-if="menuItem.icon" :src="`${cmsData.url}${menuItem.icon.fileAsset.versionPath}`" :alt="''" class="d-inline" />
            </li>
        </template>
    </ul>
</template>

<script>
import { gql } from 'graphql-request';
import { mapState } from 'vuex';
import { isRequestIdle } from '../../store/request-status';
import { searchDotCMS } from '../../services/cmsService';
import featureFlagsMixin from '../../mixins/featureFlagsMixin';
import SkeletonText from '../SkeletonText.vue';
import DotCmsLink from './DotCmsLink.vue';
import DotCmsImage from './DotCmsImage.vue';

export default {
    name: 'DotCmsMenu',
    mixins: [featureFlagsMixin],
    components: { DotCmsImage, DotCmsLink, SkeletonText },
    props: {
        name: {
            type: String,
            required: true,
        },
        placeholderMaxWidth: {
            type: Number,
            required: false,
        },
        placeholderMinWidth: {
            type: Number,
            required: false,
        },
        numberOfPlaceholders: {
            type: Number,
            default: 3,
        },
    },
    computed: {
        ...mapState(['requests', 'cmsData']),
        currentLocale() {
            return this.$store.state.i18n.locale;
        },
    },
    methods: {
        createQueryFields() {
            return gql`
                {
                    name
                    menuItems {
                        title
                        linkUrl
                        linkToFile {
                            fileName
                        }
                        icon {
                            fileAsset {
                                versionPath
                            }
                        }
                    }
                }
            `;
        },
        async getMenuItems() {
            this.isLoading = true;
            const contentType = 'Menu';
            const query = `+${contentType}.menuSlug_dotraw:${this.name}`;
            const fields = this.createQueryFields();

            try {
                const [menu] = await searchDotCMS({ contentType, query, fields, locale: this.currentLocale });

                this.menu = menu || {};
            } catch (e) {
                // If Menu Items fail to load, we'll fail silently, since they're typically supplemental content only
            }
            this.isLoading = false;
        },
    },
    data() {
        return {
            isLoading: false,
            menu: {},
        };
    },
    async mounted() {
        await this.loadFeatureFlags();

        if (isRequestIdle(this.requests.getCMSData)) await this.$store.dispatch('getCMSData');

        await this.getMenuItems();

        this.$watch('currentLocale', () => {
            this.getMenuItems();
        });
    },
};
</script>
