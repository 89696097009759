import axios from 'axios';
import { QuoteItemTypes } from '@/common/constants/quote';
import { fcoUrl } from '../../fcoModules/utilities';

// PRIVATE VARS/FUNCTIONS
const quoteRESTPath = fcoUrl('/worksheet/rest');

/**
 * Convert a CompleteProduct to an AddProductDTO, in order to send a list of products to a service
 *     see:
 *         * AddProductDto.java
 *         * CompleteProduct.java
 * @param  {Object} completeProduct
 * @return {Object}
 */
function completeProductToAddProductDTO(completeProduct) {
    return {
        productKey: {
            productKeyType: 'LEGACY',
            groupId: completeProduct.legacyKey.groupId,
            itemId: completeProduct.legacyKey.itemId,
        },
        quantity: completeProduct.userSpecifiedQuantity,
        origin: completeProduct.productOrigin,
        productOriginDetails: completeProduct.productOriginDetails,
    };
}

// PUBLIC METHODS
export const getQuote = (id, isEntSearchEnabled) =>
    isEntSearchEnabled ? axios.get(`${quoteRESTPath}/enterprise/${id}`) : axios.get(`${quoteRESTPath}/${id}`);

export const getMiniQuote = () => axios.get(`${quoteRESTPath}/miniquote.json`);

export const addProduct = (product, quantity) => axios.post(`${quoteRESTPath}/addproduct?quantity=${quantity}`, product);

export const addKitProducts = (products, isPrebuilt) => {
    const addKitPath = `${quoteRESTPath}/addkit${isPrebuilt ? '?prebuilt=true' : ''}`;
    return axios.post(addKitPath, products.map(completeProductToAddProductDTO));
};

export const saveLabor = ({ description, rate, hours }, saveLaborOptions = {}) => {
    const { isEntSearchEnabled, worksheetId, worksheetVehicleId, partTypeId, group, subgroup } = saveLaborOptions;
    const data = {
        description,
        customerPrice: rate,
        quantity: hours,
        type: QuoteItemTypes.LABOR,
        laborGroup: group,
        laborSubgroup: subgroup,
        laborPartTypeId: partTypeId,
    };
    const url = isEntSearchEnabled
        ? fcoUrl(`/worksheet/rest/enterprise/${worksheetId}/vehicle/${worksheetVehicleId}/nonProductItem`)
        : fcoUrl(`/worksheet/rest/${worksheetId}/vehicle/${worksheetVehicleId}/nonProductItem`);
    return axios.post(url, data);
};

export const searchQuotes = ({ keywords, searchType, startDate, endDate }, recent = false) =>
    axios.get(`${quoteRESTPath}/search`, { params: { keywords, searchType, startDate, endDate, recent } });

export const getOrderStatus = (confirmationNumber) => axios.get(`${quoteRESTPath}/status`, { params: { confirmationNumber } });

export const newQuote = () => axios.post(`${quoteRESTPath}/new`);
