<template>
    <div>
        <a v-if="isChatReady" href="javascript:void(0)" :style="showUnderline ? '' : 'text-decoration: none;'" @click.prevent.stop="openChat">
            <Icon name="comments-o" class="clickable text-md align-text-bottom" />{{ label }}
        </a>
        <VueSkeletonLoader v-else type="rect" :width="125" :height="18" :rounded="true" />
    </div>
</template>

<script>
import { mapState } from 'vuex';
import VueSkeletonLoader from 'skeleton-loader-vue';
import Icon from './Icon.vue';
import { openChat } from '../utilities/oracleChat';

export default {
    name: 'ChatButton',
    components: { VueSkeletonLoader, Icon },
    props: {
        label: {
            type: String,
            required: true,
        },
        showUnderline: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        ...mapState(['isChatReady']),
    },
    methods: {
        openChat,
    },
};
</script>

<style scoped lang="scss">
a {
    color: inherit;

    .icon {
        margin-right: 3px;
        margin-bottom: 1px;
    }
}
</style>
