var render = function render() {
  var _vm$company
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "header",
    {
      staticClass: "fco-header main-header",
      attrs: {
        id: "top",
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "fco-header-container d-flex",
        },
        [
          _c("HeaderBrandLink"),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "fco-header-right d-flex d-print-none",
            },
            [
              _c(
                "nav",
                {
                  staticClass: "main-nav d-none d-lg-flex",
                },
                _vm._l(_vm.navItems, function (navItem) {
                  return _c(
                    "FcoLink",
                    {
                      key: navItem.id,
                      class: ["main-nav-item", navItem.class],
                      attrs: {
                        to: navItem.route,
                        href: navItem.url,
                        id: navItem.id,
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "no-wrap",
                        },
                        [_vm._v(_vm._s(navItem.text1))]
                      ),
                      _vm._v(
                        "\n                    " +
                          _vm._s(navItem.text2) +
                          "\n                "
                      ),
                    ]
                  )
                }),
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "ml-auto my-auto pl-3 d-lg-none",
                },
                [
                  _c(
                    "button",
                    {
                      staticClass: "nav-toggle",
                      attrs: {
                        type: "button",
                      },
                      on: {
                        click: function ($event) {
                          _vm.isMobileMenuOpen = !_vm.isMobileMenuOpen
                        },
                      },
                    },
                    [
                      _c("icon", {
                        attrs: {
                          name: "bars",
                          color: "green",
                        },
                      }),
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.fcoM["rs.homeMain.menu"]) +
                          "\n                "
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "transition",
        {
          attrs: {
            name: "mobile-menu",
          },
          on: {
            "before-enter": _vm.handleMobileMenuOpen,
            "after-leave": _vm.handleMobileMenuClosed,
          },
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isMobileMenuVisible,
                  expression: "isMobileMenuVisible",
                },
              ],
              ref: "mobileMenu",
              staticClass: "mobile-menu-container",
              on: {
                click: _vm.handleMobileMenuClick,
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "mobile-menu",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "mobile-menu-header p-3 mb-2",
                    },
                    [
                      _vm._v(
                        _vm._s(
                          (_vm$company = _vm.company) === null ||
                            _vm$company === void 0
                            ? void 0
                            : _vm$company.accountName
                        )
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "nav",
                    {
                      staticClass: "nav flex-column mobile-nav",
                    },
                    _vm._l(_vm.navItems, function (navItem) {
                      return _c(
                        "FcoLink",
                        {
                          key: navItem.id,
                          class: [
                            "nav-link mb-2 mobile-nav-item",
                            navItem.class,
                          ],
                          attrs: {
                            to: navItem.route,
                            href: navItem.url,
                            id: navItem.id,
                          },
                        },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(navItem.mobileText) +
                              "\n                    "
                          ),
                        ]
                      )
                    }),
                    1
                  ),
                  _vm._v(" "),
                  _c("hr", {
                    staticClass: "mobile-menu-divider",
                  }),
                  _vm._v(" "),
                  _c("admin-bar", {
                    staticClass: "mobile-admin-bar px-0",
                    attrs: {
                      isDesktop: false,
                      direction: "vertical",
                    },
                  }),
                ],
                1
              ),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }