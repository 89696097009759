import Vue from 'vue';
import VeeValidate, { ValidationProvider, ValidationObserver } from 'vee-validate';
import FcoMqPlugin from 'fco/src/vue/plugins/mq';
import FcoLoadingPlugin from 'fco/src/vue/components/loading';
import FcoToastPlugin from 'fco/src/vue/components/toast';

import '@/common/axios.config';
import fcoI18n from '@/common/plugins/fcoI18n';
import fcoUrlMixin from '@/common/mixins/fcoUrl';
import oneShotMessagesMixin from '@/common/mixins/oneShotMessages';
import store from '@/common/store/store';
import BaseApp from '@/common/views/BaseApp.vue';
import '@/common/validation';
import 'fcoModules/cookies';
import { mapGetters, mapState } from 'vuex';
import { isRequestIdle, requestSettled } from './store/request-status';
import shopDealerControlCheck from './mixins/shopDealerControlCheck';
import { isPunchoutLogin, trackPunchoutSession } from './store/store-utils';

const content = document.querySelector('#content');

if (isPunchoutLogin()) {
    trackPunchoutSession();
    // remove the query parameter. we don't need it anymore
    window.history.replaceState(null, '', window.location.pathname);
}

export const configureVueApp = function configureVueApp() {
    const { optionMergeStrategies } = Vue.config;
    optionMergeStrategies.staticData = optionMergeStrategies.methods;

    Vue.mixin({
        computed: {
            isSPA() {
                return this.$store.state.isSPA;
            },
        },
    });
    Vue.mixin(oneShotMessagesMixin);
    Vue.mixin(fcoUrlMixin);
    Vue.use(fcoI18n);
    Vue.use(FcoMqPlugin);
    Vue.use(FcoLoadingPlugin);
    Vue.use(FcoToastPlugin);
    Vue.use(VeeValidate, {
        classNames: {
            invalid: 'error',
        },
        mode: 'eager',
    });

    Vue.component('ValidationProvider', ValidationProvider);
    Vue.component('ValidationObserver', ValidationObserver);
};

export const createVueApp = function createVueApp(AppComponent, { router, store: vuexStore = store, element = content, baseAppConfig = {} } = {}) {
    configureVueApp();

    const inheritedAttrs = Object.fromEntries([...element.attributes].map((item) => [item.name, item.value]));

    return new Vue({
        el: element,
        render(h) {
            return h(
                BaseApp,
                {
                    ...baseAppConfig,
                    attrs: {
                        ...baseAppConfig.attrs,
                        ...inheritedAttrs,
                    },
                },
                [h(AppComponent)]
            );
        },
        store: vuexStore,
        router,
        mixins: [shopDealerControlCheck],
        computed: {
            ...mapState(['requests', 'user']),
            ...mapGetters(['isTeamMember']),
        },
        async mounted() {
            if (isRequestIdle(this.requests.getAppData)) this.$store.dispatch('getAppData');
            if (isRequestIdle(this.requests.getUser)) this.$store.dispatch('getUser');

            this.getOneShotMessages();

            await requestSettled(() => this.requests.getUser);

            // Per RWD-6080, this global ForeSee property is here for the 3rd party script, so the survey can be hidden for O'Reilly team members
            window.fco = window.fco || {};
            fco.hideForesee = this.isTeamMember;
        },
    });
};
