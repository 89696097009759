<template>
    <div v-if="asset.name && cmsData.url" data-qa="banner-image">
        <component :is="bannerImage.linkUrl ? 'a' : 'span'" :href="bannerImage.linkUrl">
            <DotCmsImage :src="src" :alt="alt" :width="width" :height="height" :constrainWidth="constrainWidth" :constrainHeight="constrainHeight" />
        </component>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { isRequestIdle } from '../../store/request-status';
import DotCmsImage from './DotCmsImage.vue';

const assetProps = {
    asset: 'asset',
    tabletAsset: 'tabletAsset',
    mobileAsset: 'mobileAsset',
};

export default {
    name: 'BannerImage',
    props: {
        bannerImage: {
            type: Object,
            required: true,
            validator: (bannerImage) =>
                ['identifier', 'description', 'linkUrl', 'asset', 'tabletAsset', 'mobileAsset'].every((prop) => Object.hasOwn(bannerImage, prop)),
        },
        maxWidth: {
            type: Number,
            required: false,
        },
        maxHeight: {
            type: Number,
            required: false,
        },
    },
    computed: {
        ...mapState({
            cmsData: ({ cmsData }) => cmsData || {},
            cmsDataRequest: ({ requests }) => requests.getCMSData,
        }),
        assetProp() {
            // For each viewport below desktop, check to see if the content author has indicated a different asset should be used for that viewport
            // (instead of the matching file upload)
            if (this.$fcoMq.isSmDown) {
                const { mobileAssetSetting } = this.bannerImage;
                if (assetProps[mobileAssetSetting]) return assetProps[mobileAssetSetting];
                return assetProps.mobileAsset;
            }
            if (this.$fcoMq.isMdDown) {
                const { tabletAssetSetting } = this.bannerImage;
                if (assetProps[tabletAssetSetting]) return assetProps[tabletAssetSetting];
                return assetProps.tabletAsset;
            }
            return assetProps.asset;
        },
        asset() {
            return this.bannerImage[this.assetProp];
        },
        src() {
            if (!this.asset?.name) return '';
            const src = `${this.cmsData.url}/dA/${this.bannerImage.identifier}`;
            if (this.assetProp !== assetProps.asset) return `${src}/${this.assetProp}`;
            return src;
        },
        width() {
            if (!this.asset?.name) return 0;
            if (this.maxWidth && this.asset.width > this.maxWidth) return this.maxWidth;
            return this.asset.width ?? undefined;
        },
        height() {
            if (!this.asset?.name) return 0;
            if (this.maxHeight && this.asset.height > this.maxHeight) return this.maxHeight;
            return this.asset.height ?? undefined;
        },
        constrainWidth() {
            return this.width && this.width === this.maxWidth;
        },
        constrainHeight() {
            return this.height && this.height === this.maxHeight;
        },
        alt() {
            return this.bannerImage?.description ?? '';
        },
    },
    mounted() {
        if (isRequestIdle(this.cmsDataRequest)) this.$store.dispatch('getCMSData');
    },
    components: { DotCmsImage },
};
</script>
