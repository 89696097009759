<template>
    <VueSkeletonLoader :width="finalWidth" height="1.5em" />
</template>

<script>
import VueSkeletonLoader from 'skeleton-loader-vue';

export default {
    name: 'SkeletonText',
    components: { VueSkeletonLoader },
    props: {
        width: {
            type: [Number, String],
            required: false,
        },
        maxWidth: {
            type: Number,
            default: 350,
        },
        minWidth: {
            type: Number,
            default: 100,
        },
    },
    computed: {
        finalWidth() {
            if (this.width !== undefined) return this.width;

            const maxWidth = Math.abs(this.maxWidth);
            const minWidth = Math.abs(this.minWidth);
            const difference = maxWidth - minWidth;
            const randomWidth = Math.random() * (difference + 1) + this.minWidth;

            return `${Math.floor(randomWidth)}px`;
        },
    },
};
</script>
