export const Role = {
    SUPERUSER: 'SUPERUSER',
    SYSADMIN: 'SYSADMIN',
    INSTALLER_SUPPORT: 'INSTALLER_SUPPORT',
    COMPANY_MANAGER: 'COMPANY_MANAGER',
    SHOP_MANAGER: 'SHOP_MANAGER',
    MARKETING: 'MARKETING',
    OREILLY_EMPLOYEE: 'OREILLY_EMPLOYEE',
    SHOP_EMPLOYEE: 'SHOP_EMPLOYEE',
    DEMO_USER: 'DEMO_USER',
    SMS: 'SMS',
};

export const CustomerType = {
    MITCHELL: 'MITCHELL',
};

export const BgColorPreference = {
    WHITE: 1,
    GRAY: 3,
};

export const UserStatus = {
    ACTIVE: 'ACTIVE',
    INACTIVE: 'INACTIVE',
    DISABLED: 'DISABLED',
    DELETED: 'DELETED',
};

export const COMPANY_MANAGER_GROUP_ID = 4;

export const Locale = {
    EN: 'en',
    ES: 'es',
};
