<template>
    <picture v-if="adjustedSrc">
        <!-- Use WebP (if supported) -->
        <source type="image/webp" :srcset="!adjustedSrc ? '' : `${adjustedSrc}/webp?language_id=${languageId}`" />
        <img :src="!adjustedSrc ? '' : `${adjustedSrc}?language_id=${languageId}`" :alt="alt" :width="width" :height="height" class="img-fluid" />
    </picture>
</template>

<script>
import { mapState } from 'vuex';
import { isRequestIdle } from '../../store/request-status';
import { getDotCMSLanguageIdFromLocale } from '../../utilities/cmsUtilities';

export default {
    name: 'DotCmsImage',
    props: {
        // Simply pass an identifier for an image in dotCMS to render it in the page
        identifier: {
            type: String,
            required: false,
        },
        // In some cases, just using "identifier" will not suffice since you may need to customize the full src.
        // This prop will override the identifier.
        src: {
            type: String,
            required: false,
        },
        // For a11y. Pass an empty string if you don't have one.
        alt: {
            type: String,
            required: true,
        },
        width: {
            type: Number,
            required: false,
        },
        height: {
            type: Number,
            required: false,
        },
        // resizes the image on the server according to width dimension
        constrainWidth: {
            type: Boolean,
            default: false,
        },
        // resizes the image on the server, cropping the height dimension according to the provided height.
        constrainHeight: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        ...mapState(['requests', 'cmsData']),
        ...mapState('i18n', ['locale']),
        languageId() {
            return getDotCMSLanguageIdFromLocale(this.locale);
        },
        adjustedSrc() {
            if (!this.cmsData?.url) return '';

            if (!this.src && !this.identifier) {
                throw new Error('You must specify either an "identifier" or "src"');
            }

            const baseUrl = this.src || `${this.cmsData.url}/dA/${this.identifier}`;
            const widthPart = this.constrainWidth ? `/${this.width}w/${this.width}cw` : '';
            const heightPart = this.constrainHeight ? `/${this.height}ch` : '';

            return `${baseUrl}${widthPart}${heightPart}`;
        },
    },
    mounted() {
        if (isRequestIdle(this.requests.getCMSData)) this.$store.dispatch('getCMSData');
    },
};
</script>
