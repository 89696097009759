<template>
    <form id="vinNumber" @submit.prevent="handleVinSubmit">
        <label for="vin">{{ fcoM['rs.homeMain.enterVin'] || '&nbsp;' }}</label>
        <div class="input-group">
            <input
                type="text"
                id="vin"
                :class="{ error: validationError }"
                minlength="5"
                maxlength="17"
                data-qa="vin-input"
                v-model="inputValue"
                @input="filterInput"
                :placeholder="fcoM['rs.homeMain.enterVinPlaceholder']"
            />
            <button class="gtm-vin-search input-group-append button px-2 px-xl-3 py-1 mb-0" data-qa="vin-submit-button">
                <span class="d-sm-none d-lg-inline">{{ fcoM['common.go'] || '&nbsp;' }}</span>
                <Icon name="chevron-right" />
            </button>
        </div>
        <span v-if="validationError" class="error">{{ validationErrorMsg }}</span>
        <QuoteSelectionModal
            v-if="showNewQuotePrompt"
            @close="showNewQuotePrompt = false"
            @start-new-quote="handleQuotePromptAnswer(false)"
            @use-existing-quote="handleQuotePromptAnswer(true)"
        />
    </form>
</template>

<script>
import axios from 'axios';
import vsMixin from '../../mixins/vsMixin';
import Icon from '../Icon.vue';
import QuoteSelectionModal from './QuoteSelectionModal.vue';

export default {
    name: 'VinLookup',
    mixins: [vsMixin],
    data() {
        return {
            validationError: false,
            validationErrorMsg: '',
            inputValue: '',
            showNewQuotePrompt: false,
        };
    },
    components: { Icon, QuoteSelectionModal },
    methods: {
        filterInput() {
            this.inputValue = this.inputValue.replace(/[^a-z0-9]/gi, '');
        },
        handleQuotePromptAnswer(keepExistingQuote) {
            this.userWantsExistingQuote = keepExistingQuote;
            this.showNewQuotePrompt = false;
            this.selectVehicle(true);
        },
        handleVinSubmit() {
            this.validationError = false;

            if (this.inputValue.length < 5) {
                const errorText = !this.inputValue.length ? this.fcoM['validation.required'] : this.fcoM['rs.homeMain.invalidVin'];

                this.validationError = true;
                this.validationErrorMsg = errorText;

                return;
            }

            /* trigger vin search event in Google Analytics */
            this.analytics({
                event: this.GTM.Action.VEHICLE_SELECTOR,
                data: {
                    eventAction: 'VIN Search',
                },
            });

            this.selectVehicle();
        },
        async selectVehicle(ignoreQuoteCheck = false) {
            if (this.productsInCurrentQuote && this.promptAboutQuote && !ignoreQuoteCheck) {
                this.showNewQuotePrompt = true;
                return;
            }
            const loading = this.$fcoLoading(this.fcoM['rs.getparts.selectingVehicleLoading']);

            try {
                const {
                    data: { vehicle },
                } = await axios.post(this.fcoUrl(`/vehicle/select/defaultByVin?quote=${this.keepExistingQuoteFinal}`), { vin: this.inputValue });
                this.handleVehicleSelectSuccess({ gaEventAction: 'vin', vehicle });
                this.inputValue = '';
                if (this.preventNavigationOnVehicleUpdate || this.isSPA) {
                    loading.remove();
                }
            } catch (error) {
                this.validationError = true;
                this.validationErrorMsg = this.fcoM['rs.homeMain.invalidVin'];
                loading.remove();
            }

            /* trigger VIN found event in Google Analytics */
            this.analytics({
                event: this.GTM.Action.VEHICLE_SELECTOR,
                data: {
                    eventAction: 'VIN Found',
                },
            });
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~scssVariables/config';

input.error {
    border-bottom-left-radius: 0;
}

input:not(:focus) + .button:not(:focus) {
    background: #fff;
    border-color: $green;
    color: $green;
}
</style>
