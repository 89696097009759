<template>
    <a :href="href"
        ><slot>{{ linkText }}</slot></a
    >
</template>

<script>
import { mapState } from 'vuex';
import { isRequestIdle } from '../../store/request-status';
import { getDotCMSLanguageIdFromLocale } from '../../utilities/cmsUtilities';

export default {
    name: 'DotCmsLink',
    props: {
        // Simply pass an identifier for a dotAsset to render the link
        identifier: {
            type: String,
            required: false,
        },
        // In addition to (or in place of) the identifier, you can pass the file name
        fileName: {
            type: String,
            required: false,
        },
        // Pass this in to display a custom label instead of the fileName
        label: {
            type: String,
            required: false,
        },
    },
    computed: {
        ...mapState(['requests', 'cmsData']),
        ...mapState('i18n', ['locale']),
        linkText() {
            return (this.label ? this.label : this.fileName) || this.href;
        },
        languageId() {
            return getDotCMSLanguageIdFromLocale(this.locale);
        },
        href() {
            const baseURL = this.cmsData?.url;

            if (!baseURL) return '#';

            if (!this.identifier && !this.fileName) {
                throw new Error('You must specify either an "identifier" or "src"');
            }

            const identifierPart = this.identifier ? `/dA/${this.identifier}` : '';
            const fileNamePart = this.fileName ? `/${encodeURIComponent(this.fileName)}` : '';

            return `${baseURL}${identifierPart}${fileNamePart}?language_id=${this.languageId}`;
        },
    },
    mounted() {
        if (isRequestIdle(this.requests.getCMSData)) this.$store.dispatch('getCMSData');
    },
};
</script>
