import { Validator } from 'vee-validate';
import { state } from '@/common/store/store';
import * as test from './tests';

const getFcoM = (key) => state.i18n?.messages?.[key] ?? '';

/**
 * Custom validation rules
 * The property name is the name of the rule.
 */
const customRules = {
    basicChars: {
        getMessage: () => getFcoM('validation.invalidInput'),
        validate: test.isBasicChars,
    },
    basicCharsMultiline: {
        getMessage: () => getFcoM('validation.invalidInput'),
        validate: test.isBasicCharsMultiline,
    },
    personName: {
        getMessage: () => getFcoM('validation.invalidInput'),
        validate: test.isPersonName,
    },
    poMask: {
        getMessage: () => getFcoM('validation.poNumber'),
        validate: test.isValidPoMask,
    },
    poMaskLength: {
        getMessage: () => getFcoM('validation.poNumberLength'),
        validate: test.isValidPoMaskLength,
    },
    loginName: {
        getMessage: () => getFcoM('validation.invalidInput'),
        validate: test.isValidLoginName,
    },
    numberWithCommas: {
        getMessage: () => getFcoM('validation.numberWithCommas'),
        validate: test.isNumberWithCommas,
    },
    decimalPlaces: {
        getMessage: () => getFcoM('validation.decimalPlaces'),
        validate: (value, args) => {
            const parts = value.toString().split('.');

            if (parts.length > 1) {
                return parts[1].length <= args[0];
            }

            return true;
        },
    },
    usPhone: {
        getMessage: () => getFcoM('validation.phoneUS'),
        validate: test.isUsPhone,
    },
    zipCode: {
        getMessage: () => getFcoM('validation.zip'),
        validate: test.isValidZipCode,
    },
    fcoEmail: {
        getMessage: () => getFcoM('validation.email'),
        validate: test.isFcoEmail,
    },
    vin: {
        getMessage: () => getFcoM('validation.vin'),
        validate: test.isValidVIN,
    },
    noSpace: {
        getMessage: () => getFcoM('validation.noSpace'),
        validate: test.hasNoSpaces,
    },
};

/**
 * Rule registration
 */
const ruleKeys = Object.keys(customRules);
ruleKeys.forEach((ruleKey) => {
    Validator.extend(ruleKey, customRules[ruleKey]);
});

/**
 * Localization
 * If the user's locale is anything other than English, fetch the corresponding VeeValidate dictionary
 * Supply the dictionary with existing messages for our custom rules, as those are translated by the backend
 */
const { language } = navigator;
const [validatorLocale] = language.split('-');

if (validatorLocale !== 'en') {
    /**
     * need to disable eslint on next line, using string literal breaks the linter for dynamic imports.
     * more info and other ideas/workarounds here https://github.com/babel/babel-eslint/issues/799
     */

    // eslint-disable-next-line
    import(/* @vite-ignore */ 'vee-validate/dist/locale/' + validatorLocale).then(({ name, messages: localeMessages }) => {
        Validator.localize(validatorLocale, {
            name,
            messages: {
                ...localeMessages,

                // For any custom rules, use the message provided by the backend
                ...ruleKeys.reduce((mappedMessages, ruleKey) => {
                    mappedMessages[ruleKey] = customRules[ruleKey].getMessage;
                    return mappedMessages;
                }, {}),
            },
        });
    });
}
