var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    {
      class: [
        "info-bar",
        {
          stuck: _vm.isStuck,
        },
      ],
      attrs: {
        id: "infoBar",
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "content d-flex",
        },
        [
          _c(
            "div",
            {
              staticClass: "info-col vs-wrap",
            },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isVehicleDataChecked,
                      expression: "isVehicleDataChecked",
                    },
                  ],
                  class: [
                    "vehicle-selector-toggle gtm-vehicle-selector-toggle",
                    {
                      open: _vm.isVsOpen,
                      forced: _vm.isVsForced,
                    },
                  ],
                  attrs: {
                    "data-qa": "vehicle-link",
                  },
                  on: {
                    click: _vm.toggleVehicleSelector,
                  },
                },
                [
                  _vm.vehicle
                    ? _c("Icon", {
                        staticClass: "d-md-none text-sm",
                        attrs: {
                          name: "check-square-o",
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    [
                      _c("Icon", {
                        staticClass: "d-block text-md",
                        attrs: {
                          name: "car",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "strong",
                    {
                      class: [
                        "px-2 d-none d-md-block",
                        {
                          "vehicle-name": !!_vm.vehicle,
                        },
                      ],
                    },
                    [_vm._v(_vm._s(_vm.vehicleSelectorButtonText))]
                  ),
                  _vm._v(" "),
                  _c("Icon", {
                    staticClass: "d-none d-md-inline-block text-md",
                    attrs: {
                      name: "caret-down",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("VehicleSelector", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isVehicleDataChecked && _vm.isVsOpen,
                    expression: "isVehicleDataChecked && isVsOpen",
                  },
                ],
                ref: "vehicleSelector",
              }),
              _vm._v(" "),
              !_vm.isVehicleDataChecked
                ? _c(
                    "div",
                    {
                      staticClass: "d-flex p-2",
                    },
                    [
                      _c("vue-skeleton-loader", {
                        attrs: {
                          type: "circle",
                          width: 30,
                          height: 30,
                        },
                      }),
                      _vm._v(" "),
                      _vm.$fcoMq.isMdUp
                        ? _c("vue-skeleton-loader", {
                            staticClass: "ml-1",
                            attrs: {
                              type: "rect",
                              width: 150,
                              height: 30,
                              rounded: true,
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "info-col search-goto flex-grow-1",
            },
            [
              _c(
                "div",
                {
                  staticClass: "row",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "col flex-grow-1",
                    },
                    [
                      _c("ProductSearch", {
                        on: {
                          focus: _vm.handleSearchFocus,
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "ml-2 mr-3 p-0",
                    },
                    [_c("MiniQuote")],
                    1
                  ),
                ]
              ),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }