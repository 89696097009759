<template>
    <FcoDropdown :show.sync="isOpen">
        <template #toggle>
            <button type="button" class="language-select-button" :class="[{ active: isOpen }, buttonClass]" @click="isOpen = !isOpen">
                <span class="language-select-label" :class="labelClass">{{ fcoM['common.language'] }}</span>
                <span class="no-wrap">
                    <svg
                        class="i18n-icon"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="1.5"
                        viewBox="0 0 24 24"
                    >
                        <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M12 21a9.004 9.004 0 0 0 8.716-6.747M12 21a9.004 9.004 0 0 1-8.716-6.747M12 21c2.485 0 4.5-4.03 4.5-9S14.485 3 12 3m0 18c-2.485 0-4.5-4.03-4.5-9S9.515 3 12 3m0 0a8.997 8.997 0 0 1 7.843 4.582M12 3a8.997 8.997 0 0 0-7.843 4.582m15.686 0A11.953 11.953 0 0 1 12 10.5c-2.998 0-5.74-1.1-7.843-2.918m15.686 0A8.959 8.959 0 0 1 21 12c0 .778-.099 1.533-.284 2.253m0 0A17.919 17.919 0 0 1 12 16.5a17.92 17.92 0 0 1-8.716-2.247m0 0A9.015 9.015 0 0 1 3 12c0-1.605.42-3.113 1.157-4.418"
                        />
                    </svg>
                    {{ currentLocaleLabel }}
                    <Icon name="chevron-down" />
                </span>
            </button>
        </template>

        <button
            v-for="{ label, value } in localeOptions"
            :key="value"
            class="dropdown-item mb-0 rounded-0"
            :class="{ active: isCurrentLocal(value) }"
            type="button"
            @click="handleSelect(value)"
        >
            {{ label }}
        </button>
    </FcoDropdown>
</template>

<script>
import { FcoDropdown } from 'fco/src/vue/components/dropdown';
import { mapGetters, mapState } from 'vuex';
import Icon from './Icon.vue';

export default {
    name: 'LanguageSelectButton',
    components: { FcoDropdown, Icon },
    props: {
        buttonClass: {
            type: [String, Object, Array],
            default: () => [],
        },
        labelClass: {
            type: [String, Object, Array],
            default: () => [],
        },
    },
    data() {
        return {
            isOpen: false,
        };
    },
    computed: {
        ...mapState('i18n', {
            currentLocale: ({ locale }) => locale,
        }),
        ...mapGetters('i18n', ['localeOptions']),
        currentLocaleLabel() {
            return this.localeOptions.find(({ value }) => value === this.currentLocale)?.label || this.fcoM['common.english'];
        },
    },
    methods: {
        isCurrentLocal(locale) {
            return locale === this.currentLocale;
        },
        handleSelect(locale) {
            this.$store.dispatch('i18n/changeLocale', { locale });
            this.isOpen = false;
        },
    },
};
</script>

<style scoped lang="scss">
@import '~scssVariables/config';
@import '~scssVariables/mixins';

.language-select-button {
    margin: 0;
    text-align: left;
}

.language-select-label {
    font-size: 0.8125em;
    display: block;
    font-weight: normal;
}

.i18n-icon {
    display: inline-block;
    width: 1rem;
    height: 1rem;
    vertical-align: text-top;
}
</style>
