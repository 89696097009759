<template>
    <div class="content d-none d-md-block">
        <h2 class="sr-only">{{ fcoM['rs.home.marketingBoxesHeader'] }}</h2>

        <div class="row">
            <BannerZone
                v-for="(marketingBox, index) in marketingBoxes"
                :key="`marketingBox${index}`"
                :name="marketingBox"
                :class="['col', `gtm-login-marketing-${index + 1}`]"
                aspect-ratio="248 / 269"
            >
                <template #beforeImages="{ banners }">
                    <a :href="banners?.[0]?.linkUrl" class="text-decoration-none">
                        <FcoTitleBar class="text-center font-oswald">
                            <span>{{ banners?.[0]?.description }}</span>
                            <Icon name="chevron-right" />
                        </FcoTitleBar>
                    </a>
                </template>
            </BannerZone>
        </div>
    </div>
</template>

<script>
import BannerZone from '@/common/components/cmsContent/BannerZone.vue';
import { FcoTitleBar } from 'fco/src/vue/components/titlebar';
import Icon from '../../../common/components/Icon.vue';

export default {
    name: 'MarketingBoxes',
    components: { BannerZone, FcoTitleBar, Icon },

    data() {
        return {
            marketingBoxes: ['about-oreilly', 'about-first-call-online', 'professional-services', 'marketing-and-promotions'],
        };
    },
};
</script>
