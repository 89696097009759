/**
 * Searches through an array of objects to find the object containing a key that matches passed key.
 * If an object is found, it returns that objects key value. If no object is found
 * it returns false.
 *
 * @param array
 * @param key
 * @returns {string} || {boolean}
 */
function findDistinctType(array, key) {
    const object = array.find((item) => Object.keys(item).includes(key));
    return object ? object[key] : false;
}

/**
 * Grabs the session variables which are parsed into an array of objects.
 * Checks the session variables to determine how to store the table number's sort and reverse value.
 *
 * @param sortName
 * @param reverse
 * @param page
 * @param tableNumber
 */
export function storeTableState(sortName, reverse, page, tableNumber) {
    const sessionReverse = JSON.parse(window.sessionStorage.getItem('tableStateReverse')) || [];
    const sessionSort = JSON.parse(window.sessionStorage.getItem('tableStateSortName')) || [];
    const sessionPage = JSON.parse(window.sessionStorage.getItem('tableStatePage')) || [];
    let preReverse = false;
    let sortIndex = -1;
    let reverseIndex = -1;
    let pageIndex = -1;

    // determine the index for the sort property based off table number.
    if (sessionSort.length > 0) {
        sortIndex = sessionSort.findIndex((x) => Object.keys(x)[0] === tableNumber);
        if (sortIndex !== -1 && sessionSort[sortIndex][tableNumber] !== sortName) {
            sessionSort[sortIndex][tableNumber] = sortName;
            preReverse = true;
        }
    }

    // determine the index for the reverse property based off table number.
    if (sessionReverse && !preReverse) {
        reverseIndex = sessionReverse.findIndex((x) => Object.keys(x)[0] === tableNumber);
    }

    if (sessionPage.length > 0) {
        pageIndex = sessionPage.findIndex((pageData) => Object.keys(pageData)[0] === tableNumber);
        if (pageIndex !== -1) {
            sessionPage[pageIndex][tableNumber] = page;
        }
    }

    // if the table does not have a sort value, create a sort, reverse value, and page.
    if (sortIndex === -1 && !preReverse) {
        const sortObj = {};
        const reverseObj = {};
        const pageObj = {};

        sortObj[tableNumber] = sortName;
        sessionSort.push(sortObj);

        reverseObj[tableNumber] = reverse === false ? 1 : -1;
        sessionReverse.push(reverseObj);

        pageObj[tableNumber] = page;
        sessionPage.push(pageObj);
    }

    // same table and sort type, alternate reverse value.
    if (sortIndex !== -1 && reverseIndex !== -1 && sessionReverse && !preReverse) {
        sessionReverse[reverseIndex][tableNumber] = reverse === true ? -1 : 1;
    }

    // set the sort name to the array of objects {tableNumber : sortType}.
    window.sessionStorage.setItem('tableStateSortName', JSON.stringify(sessionSort));

    // set reverse to the array of objects {tableNumber : reverseValue)
    window.sessionStorage.setItem('tableStateReverse', JSON.stringify(sessionReverse));

    // set page to the array of objects {tableNumber : page)
    window.sessionStorage.setItem('tableStatePage', JSON.stringify(sessionPage));
}

/**
 * Grabs the session storage sort variables and checks to see if the passed table has any sort or
 * reverse values.
 *
 * If it does, return them as an object.
 *
 * @param tableNumber
 * @returns sort object
 */
export function determineTableState(tableNumber) {
    const sessionSort = JSON.parse(window.sessionStorage.getItem('tableStateSortName'));
    const sessionReverse = JSON.parse(window.sessionStorage.getItem('tableStateReverse'));
    const sessionPage = JSON.parse(window.sessionStorage.getItem('tableStatePage'));
    let reverse = -1;
    let sort = '';
    let page = '';

    // reverse and sort array exists.
    if (sessionReverse && sessionSort) {
        reverse = findDistinctType(sessionReverse, tableNumber);
        sort = findDistinctType(sessionSort, tableNumber);
    }
    if (sessionPage) {
        page = findDistinctType(sessionPage, tableNumber);
    }

    // needed for sorting on table load.
    if (reverse === 1) {
        reverse = undefined;
    }

    return {
        sortBy: sort,
        reverse,
        page,
    };
}

/**
 * deletes session storage sort variables.
 */
export function deleteTableState() {
    window.sessionStorage.removeItem('tableStateSortName');
    window.sessionStorage.removeItem('tableStateReverse');
    window.sessionStorage.removeItem('tableStatePage');
}

/**
 * It attempts to grab the session storage uniqueLookupID variable
 * and compares it to a newly generated uniqueLookupID.
 *
 * If they do not match, set the session storage variable with the new uniqueLookupID.
 */
export function newTableClear(uniqueLookupID, userId) {
    const prevUniqueLookupID = window.sessionStorage.getItem('uniqueLookupID');
    const prevUserId = Number(window.sessionStorage.getItem('userId'));

    // If there's not a uniqueLookupID already set or the previous uniqueLookupID does not match the new one.
    if (!prevUniqueLookupID || prevUniqueLookupID !== uniqueLookupID || !prevUserId || prevUserId !== userId) {
        deleteTableState();
        window.sessionStorage.setItem('uniqueLookupID', uniqueLookupID);
        window.sessionStorage.setItem('userId', userId);
    }
}
