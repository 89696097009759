<template>
    <div class="go-to-quote-container">
        <div v-if="emptyQuote" class="no-quote d-none d-sm-flex">
            <a href="javascript:void(0)" class="gtm-miniquote-quote-empty" data-qa="go-to-quote-button" @click.prevent="handleGoQuoteClick">
                {{ fcoM['common.goToQuote'] }}
                <Icon name="chevron-right" class="text-md" />
            </a>
        </div>
        <div v-else class="quote-wrapper d-none d-sm-flex">
            <strong :class="['items-cost', { 'dropdown-activated': expandMiniQuote }]" @click="toggleMiniQuote" data-qa="mini-quote-toggle">
                <span>${{ subtotal }} ({{ quoteDetails.totalItems }})</span>
                <Icon :name="miniQuoteCaretName" class="clickable text-md" />
            </strong>
            <FcoLink
                :to="`/quote/details/${currentQuoteId}`"
                :href="fcoUrl(`/worksheet/${currentQuoteId}/show.html`)"
                class="button d-inline mb-0"
                data-qa="go-to-quote-button"
            >
                {{ fcoM['common.goToQuote'] }}
                <Icon name="chevron-right" class="text-md" />
            </FcoLink>
            <div v-if="expandMiniQuote" class="quote-items-dropdown" v-on-clickaway="toggleMiniQuote">
                <div class="dropdown-content">
                    <ul class="items-in-quote">
                        <li v-for="product in quoteProducts" :key="product.miniQuoteKey" class="d-flex p-2">
                            <div>
                                <span @click.prevent="removeProduct(product)" data-qa="mini-quote-remove-product">
                                    <Icon name="times" class="clickable red text-md mr-1" />
                                </span>
                            </div>
                            <div class="flex-grow-1">
                                <div class="part-name">{{ product.itemDescription }}</div>
                                <div class="part-details">
                                    {{ fcoM['rs.infoBar.quantityLabel'] }}
                                    {{ product.itemQuantity }}
                                    <MiniQuoteProductPrice :product="product" :hide-costs="isCostHidden" />
                                </div>
                            </div>
                        </li>
                    </ul>
                    <div v-show="showQuoteMask" class="quote-mask"></div>
                </div>
            </div>
        </div>
        <div class="mobile-quote-container d-sm-none">
            <a :class="['button', 'gtm-miniquote-quote', { 'disabled-btn gtm-quote-empty': emptyQuote }]" @click.prevent="handleGoQuoteClick">$</a>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { onClickaway } from 'fco/src/vue/directives/clickaway';
import axios from 'axios';
import usageTrackingService from 'fcoModules/usageTrackingService';

import Icon from './Icon.vue';
import analytics from '../../fcoModules/analytics';
import MiniQuoteProductPrice from './MiniQuoteProductPrice.vue';
import FcoLink from './FcoLink.vue';
import { isRequestError, isRequestIdle } from '../store/request-status';

export default {
    name: 'MiniQuote',
    data() {
        return {
            showQuoteMask: false,
        };
    },
    computed: {
        ...mapState({
            expandMiniQuote: (state) => state.miniQuote.expandMiniQuote,
            quoteDetails: (state) => state.miniQuote.quoteDetails,
            quoteProducts: (state) => state.miniQuote.quoteProducts,
            getMiniQuoteRequest: (state) => state.miniQuote.requests.getQuoteData,
            isCostHidden: (state) => state.isCostHidden,
        }),
        miniQuoteCaretName() {
            return this.expandMiniQuote ? 'caret-up' : 'caret-down';
        },
        currentQuoteId() {
            return this.quoteDetails.worksheetHeaderId;
        },
        emptyQuote() {
            return this.quoteDetails.totalItems === 0;
        },
        subtotal() {
            const subtotal = this.isCostHidden ? this.quoteDetails.subtotalPrice : this.quoteDetails.subtotalCost;
            return subtotal.toFixed(2);
        },
    },

    directives: { onClickaway },
    components: { Icon, MiniQuoteProductPrice, FcoLink },
    methods: {
        toggleMiniQuote() {
            const action = this.expandMiniQuote ? 'miniQuote/closeMiniQuote' : 'miniQuote/openMiniQuote';
            this.$store.dispatch(action);
        },
        async removeProduct(product) {
            const tempProduct = { ...product };
            this.showQuoteMask = true;

            try {
                const splitCatalogKey = tempProduct.catalogKey.split('_');
                const groupId = splitCatalogKey[1];
                const itemId = splitCatalogKey[0];
                const { data } = await axios.post(this.fcoUrl(`/worksheet/rest/miniquote/deleteitem.json?worksheetDetailId=${product.id}`));

                await this.$store.commit('miniQuote/setQuoteData', data);

                if (data.quoteDetails.length === 0) {
                    await this.$store.dispatch('miniQuote/closeMiniQuote');
                    this.hasQuote = false;
                }

                await usageTrackingService.removePartFromQuote(
                    {
                        vehicleId: tempProduct.vehicleId,
                        product: tempProduct,
                    },
                    groupId,
                    itemId
                );

                analytics({
                    event: 'removeFromCart',
                    data: {
                        id: groupId + itemId,
                        name: tempProduct.itemDescription,
                        category: tempProduct.analyticsCategory,
                        brand: groupId,
                        price: tempProduct.itemCost,
                        quantity: tempProduct.itemQuantity,
                    },
                });
            } catch (err) {
                this.$fcoToast.error(this.fcoM['rs.infoBar.errorDeletingQuote']);
            }
            this.showQuoteMask = false;
        },
        async handleGoQuoteClick($event) {
            $event.preventDefault();

            // If we don't have a quote yet, we need to create one before we can navigate to the quote page.
            if (this.currentQuoteId) {
                if (this.isSPA) {
                    this.$router.push(`/quote/details/${this.currentQuoteId}`);
                } else {
                    window.location.href = this.fcoUrl(`/worksheet/${this.currentQuoteId}/show.html`);
                }
                return;
            }

            const loading = this.$fcoLoading();

            try {
                await this.$store.dispatch('miniQuote/newQuote');
                if (this.isSPA) {
                    this.$router.push(`/quote/details/${this.currentQuoteId}`);
                } else {
                    window.location.href = this.fcoUrl(`/worksheet/${this.currentQuoteId}/show.html`);
                }
            } catch (error) {
                this.$fcoToast.error(this.fcoM['rs.infoBar.errorGettingQuote']);
            }

            loading.remove();
        },
    },
    watch: {
        getMiniQuoteRequest: {
            immediate: true,
            handler() {
                if (isRequestError(this.getMiniQuoteRequest)) {
                    this.$fcoToast.error(this.fcoM['rs.infoBar.errorGettingQuote']);
                }
            },
        },
    },
    async mounted() {
        if (isRequestIdle(this.getMiniQuoteRequest)) this.$store.dispatch('miniQuote/getQuoteData');
    },
};
</script>

<style lang="scss" scoped>
@import '~scssVariables/mixins';
@import '~scssVariables/config';

.go-to-quote-container {
    height: $ib-item-height;
    position: relative;

    & > div {
        height: $ib-item-height;
    }

    .no-quote {
        color: $gray-500;
        border: $border-gray;
        @include border-radius-fco;
        padding: $padding-less 45px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;

        a {
            display: block;
            color: $gray-500;
            text-decoration: none;
        }
    }

    .button {
        line-height: $ib-item-height;
        padding: 0 $padding;
        @include right-border-radius;
    }

    .items-in-quote {
        margin: 0;
    }

    .items-cost {
        cursor: pointer;
        border: 1px solid $green;
        color: $green;
        @include left-border-radius;
        background-color: white;
        line-height: $ib-item-height;
        padding: 0 10px 0 $padding;
        margin-right: -1px;
        position: relative;
        user-select: none;
    }

    .mobile-quote-container {
        display: block;
        text-align: center;
        line-height: $ib-item-height;

        .disabled-btn {
            background-color: $white;
            cursor: default;
            color: $gray-500;
            border-color: $gray-500;
        }

        .disabled-btn,
        .disabled-btn:hover {
            cursor: pointer;
        }

        a {
            height: $ib-item-height;
            width: $ib-item-height;
            padding: 0;
            font-size: 1.71em;
            border-radius: 3px;
        }
    }

    .quote-items-dropdown {
        background-color: white;
        border: $border-gray;
        @include drop-shadow;
        min-width: calc(100% + 30px);
        border-radius: 3px;
        position: absolute;
        z-index: 12;
        right: 0;
        top: $ib-item-height;

        .dropdown-content {
            position: relative;

            .quote-mask {
                position: absolute;
                background-color: white;
                opacity: 0.8;
                top: 0;
                right: 0;
                left: 0;
                bottom: 0;
            }
        }

        li {
            border-bottom: $border-light-gray;
            margin-left: 0;
            cursor: default;

            /* truncate text */
            .part-name {
                max-width: 300px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        li:last-child {
            border-bottom: none;
        }
    }

    .dropdown-activated {
        border: $border-gray;
        @include drop-shadow;
        z-index: 13;
    }

    .dropdown-activated::after {
        width: 100%;
        height: 10px;
        z-index: 9999;
        display: block;
        content: '';
        top: 35px;
        left: 0;
        background-color: white;
        position: absolute;
    }

    .part-name {
        font-weight: bold;
    }

    .part-details {
        color: $gray-600;
    }
}
</style>
