var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    _vm.tag,
    {
      tag: "component",
      staticClass: "attribute",
    },
    [
      _vm.canRemove
        ? _c(
            "div",
            {
              staticClass: "attribute-action",
            },
            [
              _vm.loading
                ? _c("Icon", {
                    attrs: {
                      name: "refresh",
                    },
                  })
                : _c(
                    "button",
                    {
                      staticClass: "remove-attr-btn",
                      attrs: {
                        type: "button",
                      },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.$emit("remove")
                        },
                      },
                    },
                    [
                      _c("Icon", {
                        staticClass: "clickable red text-sm",
                        attrs: {
                          name: "trash",
                        },
                      }),
                    ],
                    1
                  ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "flex-fill",
        },
        [
          _c(
            "span",
            {
              staticClass: "attribute-name",
            },
            [_vm._v(_vm._s(_vm.name))]
          ),
          _vm._v(":\n        "),
          _c(
            "span",
            {
              staticClass: "attribute-value",
            },
            [_vm._v(_vm._s(_vm.value))]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }