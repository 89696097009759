<template>
    <div :data-qa="`banner-zone-${name}`">
        <template v-if="isLoading || (placeholder && hasError)">
            <div v-if="placeholder" class="banner-zone-placeholder" :style="{ aspectRatio }" />
        </template>
        <template v-else-if="banners.length">
            <slot name="beforeImages" :banners="banners"></slot>

            <Carousel v-if="carousel" v-bind="{ slides: banners, ...carouselProps }">
                <template #default="banner">
                    <BannerImage :bannerImage="banner" :maxHeight="maxHeight" :maxWidth="maxWidth" />
                </template>
            </Carousel>
            <template v-else>
                <BannerImage
                    class="banner-image"
                    v-for="banner in banners"
                    :key="banner.identifier"
                    :bannerImage="banner"
                    :maxHeight="maxHeight"
                    :maxWidth="maxWidth"
                />
            </template>
        </template>
    </div>
</template>

<script>
import shuffle from 'lodash/shuffle';
import { gql } from 'graphql-request';
import BannerImage from './BannerImage.vue';
import Carousel from '../Carousel.vue';
import { searchDotCMS } from '../../services/cmsService';
import featureFlagsMixin from '../../mixins/featureFlagsMixin';

const modifedDateSort =
    (desc = true) =>
    (banners) => {
        const reverseModifier = desc ? 1 : -1;
        return banners
            .map((banner) => ({ ...banner, _modDate: new Date(banner.modDate) }))
            .sort((a, b) => (b._modDate - a._modDate) * reverseModifier)
            .map(({ _modDate, ...banner }) => banner);
    };
const bannerSortMethods = {
    random: (banners) => shuffle(banners),
    modifiedDesc: modifedDateSort(true),
    modifiedAsc: modifedDateSort(false),
};

const SortOrder = {
    RANDOM: 'random',
};

export default {
    name: 'BannerZone',
    mixins: [featureFlagsMixin],
    props: {
        // identifier for the banner zone
        name: {
            type: String,
            required: true,
        },
        // optionally show or prevent a loading placeholder while the banner zone loads
        placeholder: {
            type: Boolean,
            default: true,
        },
        // CSS aspect-ratio used for the placeholder (default is 3 / 1)
        aspectRatio: {
            type: String,
            required: false,
        },
        // render the banner zone as a carousel
        carousel: {
            type: Boolean,
            default: false,
        },
        // prop object to pass to the carousel component
        carouselProps: {
            type: Object,
            default: () => ({
                disableControls: true,
                autoplay: true,
            }),
        },
        trackRandom: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            zone: {},
            isLoading: true,
            hasError: false,
        };
    },
    computed: {
        allBanners() {
            return this.zone.bannerImages || [];
        },
        bannerCount() {
            return this.allBanners.length;
        },
        maxItems() {
            return this.zone.maxItems || this.bannerCount;
        },
        banners() {
            if (this.bannerCount < 2) return this.allBanners;

            const sortMethod = bannerSortMethods[this.zone.sort];
            const banners = sortMethod ? sortMethod(this.allBanners) : this.allBanners;

            if (this.trackRandom && this.zone.sort === SortOrder.RANDOM) {
                /* Show random banner from pool of unseen banners */
                let bannerIndex = 0;
                let banner;
                while (bannerIndex < this.bannerCount) {
                    banner = this.allBanners[bannerIndex];
                    const { identifier } = banner;

                    if (!this.viewedBanners.includes(identifier)) {
                        this.saveBannerViewed(identifier);
                        return [banner];
                    }
                    bannerIndex += 1;
                }
            }

            return banners.slice(0, this.maxItems);
        },
        maxWidth() {
            let maxWidth;

            if (this.$fcoMq.isLgUp) maxWidth = this.zone.width;
            else if (this.$fcoMq.isMdUp) maxWidth = this.zone.widthTablet;
            else maxWidth = this.zone.widthMobile;

            return maxWidth || undefined;
        },
        maxHeight() {
            let maxHeight;

            if (this.$fcoMq.isLgUp) maxHeight = this.zone.height;
            else if (this.$fcoMq.isMdUp) maxHeight = this.zone.heightTablet;
            else maxHeight = this.zone.heightMobile;

            return maxHeight || undefined;
        },
        viewedBanners() {
            const banners = window.localStorage.getItem(`viewed_banners_${this.name}`);

            if (!banners) return [];

            return banners.split(',');
        },
        currentLocale() {
            return this.$store.state.i18n.locale;
        },
    },
    methods: {
        saveBannerViewed(bannerId) {
            const savedBanners = this.viewedBanners.length ? `${this.viewedBanners.join()},${bannerId}` : `${bannerId}`;

            window.localStorage.setItem(`viewed_banners_${this.name}`, savedBanners);
        },
        async getBanners() {
            this.isLoading = true;
            const bannerAssetFields = gql`
                {
                    name
                    width
                    height
                }
            `;
            const contentType = 'BannerZone';
            const query = `+${contentType}.zoneSlug_dotraw:${this.name}`;
            const fields = gql`
                {
                    identifier
                    zoneSlug
                    name
                    maxItems
                    sort
                    width
                    widthTablet
                    widthMobile
                    height
                    heightTablet
                    heightMobile
                    bannerImages {
                        identifier
                        modDate
                        linkUrl
                        description
                        asset ${bannerAssetFields}
                        tabletAssetSetting
                        tabletAsset ${bannerAssetFields}
                        mobileAssetSetting
                        mobileAsset ${bannerAssetFields}
                    }
                }
            `;

            try {
                const [bannerZone] = await searchDotCMS({ contentType, query, fields, locale: this.currentLocale });
                this.zone = bannerZone || {};
            } catch (e) {
                // If banners fail to load, we'll fail silently, since they're typically supplemental content only
                // This hasError value allows us to keep the placeholder visible if necessary.
                this.hasError = true;
            }
            this.isLoading = false;
        },
    },
    async mounted() {
        await this.loadFeatureFlags();

        this.getBanners();
        this.$watch('currentLocale', () => {
            this.getBanners();
        });
    },
    components: { BannerImage, Carousel },
};
</script>

<style lang="scss" scoped>
@import '~scssVariables/config';

.banner-image + .banner-image {
    margin-top: 0.5rem;
}

.banner-zone-placeholder {
    aspect-ratio: 3 / 1;
    position: relative;
    width: 100%;
    background-color: $gray-200;
    overflow: hidden;
}

.banner-zone-placeholder::before {
    position: absolute;
    content: '';
    display: block;
    right: 100%;
    top: 0;
    bottom: 0;
    width: 100%;
    background-image: linear-gradient(
        to right,
        rgba($gray-400, 0) 0,
        rgba($gray-400, 0.4) 15%,
        rgba($gray-400, 0.7) 30%,
        rgba($gray-400, 1) 40%,
        rgba($gray-400, 1) 60%,
        rgba($gray-400, 0.7) 75%,
        rgba($gray-400, 0.4) 85%,
        rgba($gray-400, 0) 100%
    );
    opacity: 0.5;
    animation: loading 1s infinite;
}

@keyframes loading {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(200%);
    }
}
</style>
