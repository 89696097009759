import axios from 'axios';
import { fcoUrl } from '../../fcoModules/utilities';

// PRIVATE VARS/FUNCTIONS

// PUBLIC PROPERTIES

// PUBLIC METHODS
export const saveCompanyPreferences = ({ accountName, demo, address, companyPreference }) => {
    const payload = {
        accountName,
        demo,
        street1: address.street1,
        street2: address.street2,
        city: address.city,
        state: address.state,
        zip: address.zip,
        garageBayIdVisible: companyPreference.garageBayIdVisible,
        garageBayIdRequired: companyPreference.garageBayIdRequired,
        poVisible: companyPreference.poVisible,
        poRequired: companyPreference.poRequired,
        poValidationMask: companyPreference.poValidationMask,
        vehicleFleetIdVisible: companyPreference.vehicleFleetIdVisible,
        vehicleFleetIdRequired: companyPreference.vehicleFleetIdRequired,
    };
    return axios.post(fcoUrl('/company/savePreferences'), payload);
};
