import axios from 'axios';
import { fcoUrl } from '../../fcoModules/utilities';

// PRIVATE VARS/FUNCTIONS

// PUBLIC PROPERTIES

// PUBLIC METHODS
export const getFeatures = () => axios.get(fcoUrl('/info/features/global'));

export const getFeaturesForStore = (storeNumber) => axios.get(fcoUrl(`/info/features/store?storeNumber=${storeNumber}`));
