import axios from 'axios';
import { fcoUrl } from '../../fcoModules/utilities';

// PRIVATE VARS/FUNCTIONS
const stockOrdersRESTPath = fcoUrl('/stockorders');

// PUBLIC PROPERTIES

// PUBLIC METHODS
export const getStockOrders = ({ size = 500, page = 0 } = {}) => axios.get(stockOrdersRESTPath, { params: { size, page } });

export const getStockOrderById = (stockOrderId) => axios.get(`${stockOrdersRESTPath}/${stockOrderId}`);

export const deleteStockOrder = (stockOrderId) => axios.delete(`${stockOrdersRESTPath}/${stockOrderId}`);

export const deleteStockOrderDetail = (stockOrderId, stockOrderDetailId) =>
    axios.delete(`${stockOrdersRESTPath}/${stockOrderId}/details/${stockOrderDetailId}`);

export const getStockOrdersContainingLineItems = (lineItems) => axios.post(`${stockOrdersRESTPath}/getStockOrdersIncludingLineItems`, lineItems);

export const addStockOrder = (stockOrder) => axios.post(`${stockOrdersRESTPath}`, stockOrder);

export const addStockOrderToQuote = (partsToAddToQuote) => axios.post(fcoUrl(`/worksheet/rest/addproducts`), partsToAddToQuote);

export const updateStockOrder = (stockOrder) => axios.put(`${stockOrdersRESTPath}/${stockOrder.id}`, stockOrder);

export const createStockOrderWithProduct = ({ name, po, line, item, description, quantity }) =>
    axios.post(`${stockOrdersRESTPath}/createStockOrder`, {
        name,
        po,
        line,
        item,
        desc: description,
        quantity,
    });

export const updateStockOrderProduct = (stockOrderId, { line, item, description, quantity, note }) =>
    axios.post(`${stockOrdersRESTPath}/${stockOrderId}/details`, {
        lineCode: line,
        partNumber: item,
        partDescription: description,
        stockQty: quantity,
        note,
    });

export const uploadCsvFile = (stockOrderId, formData) => axios.post(`${stockOrdersRESTPath}/upload?id=${stockOrderId}`, formData);
