<template>
    <div>
        <div v-for="{ content, description, linkUrl, messageId } in activeBroadcastMessages" :key="messageId" class="alert alert-warning mb-0 d-flex">
            <a v-if="linkUrl !== ''" class="message-content d-block text-decoration-none text-center text-reset" :href="linkUrl">
                <div>
                    <h2>{{ description }}</h2>
                    <div>{{ content }}</div>
                </div>
            </a>
            <div v-else class="message-content text-center">
                <h2>{{ description }}</h2>
                <div>{{ content }}</div>
            </div>

            <a href="JavaScript:void(0)" @click="dismissMessage(messageId)" class="text-lg">
                <Icon name="times" class="red" />
            </a>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import Icon from '@/common/components/Icon.vue';

export default {
    name: 'BroadcastMessages',
    components: { Icon },
    data() {
        return {
            broadcastMessages: [],
        };
    },
    computed: {
        activeBroadcastMessages() {
            const now = new Date().getTime();

            if (Array.isArray(this.broadcastMessages) && this.broadcastMessages !== null) {
                return this.broadcastMessages.filter((message) => {
                    const { schedules } = message;
                    const index = schedules.findIndex((schedule) => {
                        const { startDateTime, endDateTime } = schedule;

                        return now >= startDateTime && now <= endDateTime;
                    });

                    return index > -1;
                });
            }

            return [];
        },
    },
    methods: {
        dismissMessage(messageId) {
            const index = this.broadcastMessages.findIndex((message) => message.messageId === messageId);

            this.broadcastMessages.splice(index, 1);
            axios.get(this.fcoUrl(`/message/dismiss/${messageId}`));
        },
    },
    async mounted() {
        try {
            const { data = [] } = await axios.get(this.fcoUrl('/message/get/current/BROADCAST'));

            this.broadcastMessages = data;
        } catch (error) {
            // Fail silently if the request to get broadcast messages fails
        }
    },
};
</script>

<style scoped>
.message-content {
    flex: 1;
}
</style>
