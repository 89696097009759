var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    [
      _vm.isChatReady
        ? _c(
            "a",
            {
              style: _vm.showUnderline ? "" : "text-decoration: none;",
              attrs: {
                href: "javascript:void(0)",
              },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  $event.stopPropagation()
                  return _vm.openChat.apply(null, arguments)
                },
              },
            },
            [
              _c("Icon", {
                staticClass: "clickable text-md align-text-bottom",
                attrs: {
                  name: "comments-o",
                },
              }),
              _vm._v(_vm._s(_vm.label) + "\n    "),
            ],
            1
          )
        : _c("VueSkeletonLoader", {
            attrs: {
              type: "rect",
              width: 125,
              height: 18,
              rounded: true,
            },
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }