/* eslint no-shadow: ['error', { 'allow': ['state'] }] */

import axios from 'axios';
import { fcoUrl } from '@/fcoModules/utilities';

export const state = {
    locationDetails: new Map(),
};

export const mutations = {
    setLocationDetails(state, details) {
        const { locationNumber } = details;

        state.locationDetails.set(locationNumber.toString(), details);
    },
};

export const actions = {
    async getLocationDetails({ commit, state }, locationNumbers) {
        const retrievedDetails = [];
        const locationsToRequest = [];

        /* check to see if we have already retrieved data before we request it again */
        locationNumbers.forEach((locationNumber) => {
            const details = state.locationDetails.get(locationNumber.toString());

            if (details) {
                retrievedDetails.push(details);
            } else {
                locationsToRequest.push(locationNumber);
            }
        });

        if (locationsToRequest.length > 0) {
            const response = await axios({
                method: 'post',
                url: fcoUrl('/product/multipleLocationDetailsForOPP'),
                data: locationsToRequest,
            });

            response.data.forEach((details) => {
                const { locationAddress, locationCity } = details;

                /* Formats route addresses for display */
                details.locationAddress = locationAddress.toLowerCase();
                details.locationCity = locationCity.toLowerCase();

                commit('setLocationDetails', details);
                retrievedDetails.push(details);
            });
        }

        return retrievedDetails;
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
};
