import { usPhoneRegex } from 'fcoModules/utilities';

import {
    zipRegex,
    basicCharRegex,
    basicCharMultilineRegex,
    integerRegex,
    emailRegex,
    vinRegex,
    personNameRegex,
    loginNameRegex,
    poMaskRegex,
    poWildcardRegex,
    poNumberLengthRegex,
} from 'fco/src/utils/regex';

export const isBasicChars = (value = '') => value.length === 0 || basicCharRegex.test(value);

export const isBasicCharsMultiline = (value = '') => value.length === 0 || basicCharMultilineRegex.test(value);

export const isPersonName = (value = '') => value.length === 0 || personNameRegex.test(value);

export const isValidPoMask = (value = '') => value.length === 0 || (poMaskRegex.test(value) && !poWildcardRegex.test(value));

export const isValidPoMaskLength = (value = '') => value.length === 0 || poNumberLengthRegex.test(value);

export const isValidLoginName = (value = '') => value.length === 0 || loginNameRegex.test(value);

export const isNumberWithCommas = (value = '') => integerRegex.test(String(value).replace(/,/g, ''));

export const isUsPhone = (value) => usPhoneRegex.test(value);

export const isValidZipCode = (value) => zipRegex.test(value);

export const isFcoEmail = (value) => emailRegex.test(value);

export const isValidVIN = (value) => vinRegex.test(value);

export const hasNoSpaces = (value = '') => value.indexOf(' ') < 0;
