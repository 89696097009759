var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _vm.adjustedSrc
    ? _c("picture", [
        _c("source", {
          attrs: {
            type: "image/webp",
            srcset: !_vm.adjustedSrc
              ? ""
              : `${_vm.adjustedSrc}/webp?language_id=${_vm.languageId}`,
          },
        }),
        _vm._v(" "),
        _c("img", {
          staticClass: "img-fluid",
          attrs: {
            src: !_vm.adjustedSrc
              ? ""
              : `${_vm.adjustedSrc}?language_id=${_vm.languageId}`,
            alt: _vm.alt,
            width: _vm.width,
            height: _vm.height,
          },
        }),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }