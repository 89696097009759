<template>
    <div class="carousel slide" :aria-live="isAutoplaying ? 'off' : 'polite'">
        <div class="position-relative">
            <!-- Carousel Items -->
            <div class="carousel-inner">
                <div v-for="(slide, index) in slides" :key="index" :class="getSlideClass(index)" @focusin="stopAutoplay">
                    <slot v-bind="slide" />
                </div>
            </div>

            <!-- Prevous/Next Controls -->
            <template v-if="!disableControls && slideCount > 1">
                <button class="carousel-control-prev" @click="handlePreviousClick" @focus="stopAutoplay">
                    <Icon name="chevron-left" />
                </button>
                <button class="carousel-control-next" @click="handleNextClick" @focus="stopAutoplay">
                    <Icon name="chevron-right" />
                </button>
            </template>
        </div>

        <!-- Indicators -->
        <div class="carousel-nav" v-if="!disableNav && slideCount > 1" @focusin="stopAutoplay">
            <button class="carousel-nav-control highlight-on-focus" @click="handlePreviousClick">
                <span class="sr-only">{{ fcoM['carousel.previousSlide'] }}</span>
                <Icon name="chevron-left" class="carousel-nav-icon" aria-hidden="true" />
            </button>
            <button
                v-for="itemNumber in slideCount"
                :key="itemNumber"
                :class="['carousel-nav-indicator highlight-on-focus', { active: itemNumber - 1 === activeIndex }]"
                @click="advanceSlidesBy(itemNumber - 1 - activeIndex)"
            >
                {{ stringFormat(fcoM['carousel.slideNumber'], itemNumber, slideCount) }}
            </button>
            <button class="carousel-nav-control highlight-on-focus" v-show="isAutoplaying" @click="stopAutoplay">
                <span class="sr-only">{{ fcoM['carousel.pause'] }}</span>
                <Icon name="pause" class="carousel-nav-icon" aria-hidden="true" />
            </button>
            <button class="carousel-nav-control highlight-on-focus" v-show="!isAutoplaying" @click="startAutoplay">
                <span class="sr-only">{{ fcoM['carousel.resume'] }}</span>
                <Icon name="play" class="carousel-nav-icon" aria-hidden="true" />
            </button>
            <button class="carousel-nav-control highlight-on-focus" @click="handleNextClick">
                <span class="sr-only">{{ fcoM['carousel.next'] }}</span>
                <Icon name="chevron-right" class="carousel-nav-icon" aria-hidden="true" />
            </button>
        </div>
    </div>
</template>

<script>
import delay from 'fco/src/utils/delay';
import Icon from './Icon.vue';
import { stringFormat } from '../../fcoModules/utilities';

const DIRECTION = {
    LEFT: 'left',
    RIGHT: 'right',
};
const POSITION = {
    [DIRECTION.LEFT]: 'next',
    [DIRECTION.RIGHT]: 'prev',
};

export default {
    name: 'Carousel',
    emits: ['slide-changed'],
    props: {
        slides: {
            type: Array,
            default: () => [],
        },
        autoplay: {
            type: Boolean,
            default: false,
        },
        autoplayInterval: {
            type: Number, // milliseconds
            default: 5500,
        },
        disableNav: {
            type: Boolean,
            default: false,
        },
        disableControls: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            activeIndex: 0,
            newActiveIndex: 0,
            isSliding: false,
            direction: null,
            isAutoplaying: this.autoplay,
        };
    },
    computed: {
        slideCount() {
            return this.slides.length;
        },
    },
    methods: {
        async advanceSlidesBy(count) {
            if (this.isSliding) return;

            this.newActiveIndex = (this.activeIndex + count + this.slides.length) % this.slides.length;
            this.direction = count > 0 ? DIRECTION.LEFT : DIRECTION.RIGHT;

            // Wait for [class] attributes to update before applying the animation classes
            await delay();

            this.isSliding = true;

            // Wait for slide animation to complete before resetting. Bootstrap carousel transition is 600ms.
            await delay(600);

            this.handleTransitionEnd();
            this.$emit('slide-changed', this.newActiveIndex);
        },
        handlePreviousClick() {
            this.isAutoplaying = false;
            this.advanceSlidesBy(-1);
        },
        handleNextClick() {
            this.isAutoplaying = false;
            this.advanceSlidesBy(1);
        },
        async handleTransitionEnd() {
            this.activeIndex = this.newActiveIndex;
            this.isSliding = false;
            this.direction = null;
        },
        getSlideClass(index) {
            const isActive = this.activeIndex === index;
            const isNewActive = this.newActiveIndex === index;
            const slideClass = [
                'carousel-item',
                {
                    active: isActive,
                    [`carousel-item-${this.direction}`]: this.isSliding && (isActive || isNewActive),
                },
            ];

            const position = POSITION[this.direction];
            if (isNewActive && position) {
                slideClass.push(`carousel-item-${position}`);
            }

            return slideClass;
        },
        startAutoplay() {
            this.isAutoplaying = true;
            this.autoplayIntervalId = setInterval(() => {
                this.advanceSlidesBy(1);
            }, this.autoplayInterval);
        },
        stopAutoplay() {
            this.isAutoplaying = false;
            clearInterval(this.autoplayIntervalId);
        },
        stringFormat,
    },
    watch: {
        autoplay: {
            immediate: true,
            handler(autoplay) {
                if (autoplay && this.slideCount > 1) this.startAutoplay();
                else this.stopAutoplay();
            },
        },
    },
    beforeDestroy() {
        this.stopAutoplay();
    },
    components: { Icon },
};
</script>

<style scoped lang="scss">
@import '~scssVariables/config';
@import '~scssVariables/mixins';

@mixin carousel-nav-hover {
    opacity: 1;
    background: rgba(0, 0, 0, 0.2);
    .icon {
        stroke: rgba(0, 0, 0, 0.5);
    }
}

.carousel-control-prev,
.carousel-control-next {
    margin: 0;
    border-radius: 0;
    width: 12%;
    min-width: 60px;

    .icon {
        width: 37.5%;
        min-width: 24px;
        height: auto;
        stroke: rgba(0, 0, 0, 1);
        stroke-width: 100;
    }

    &:focus {
        outline: 0 none;
        border: 0 none;
        box-shadow: none;
        opacity: 0.5;
        background: rgba(0, 0, 0, 0);
    }

    &:active,
    &:focus-visible {
        @include carousel-nav-hover;
    }

    @media (hover: hover) and (pointer: fine) {
        &:hover {
            @include carousel-nav-hover;
        }
    }
}

.carousel-nav {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;

    .carousel-nav-indicator,
    .carousel-nav-control {
        display: block;
        margin: 0 0.3125rem;
        cursor: pointer;
        border: 0 none;
        padding: 0;
        flex-shrink: 0;
        overflow: hidden;
    }
    .carousel-nav-control {
        background: none transparent;
    }
    .carousel-nav-icon {
        width: 14px;
        height: 14px;
        vertical-align: middle;
        display: inline-block;
    }
    .carousel-nav-indicator {
        text-indent: -999px;
        border-radius: 50%;
        width: 10px;
        height: 10px;

        &.active:hover {
            cursor: default;
            background: $yellow;
        }
        &:not(.active) {
            background: $gray-300;
        }
    }
}
</style>
