<template>
    <Attribute
        :tag="tag"
        :canRemove="canRemove"
        :name="attributeName"
        :value="attributeValue"
        :loading="loading"
        @remove="$emit('remove', attribute)"
    />
</template>

<script>
import Attribute from './Attribute.vue';

export default {
    name: 'VehicleAttribute',
    components: {
        Attribute,
    },
    props: {
        attribute: {
            type: Object,
            default: () => ({}),
        },
        canRemove: {
            type: Boolean,
            default: true,
        },
        tag: {
            type: String,
            default: 'div',
        },
        loading: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        attributeName() {
            return this.attribute.attributeDescription;
        },
        attributeValue() {
            return this.attribute.displayDescription ? this.attribute.displayDescription : this.attribute.valueDescription;
        },
    },
};
</script>
