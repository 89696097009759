<template>
    <span v-if="!!price">
        &ndash;
        <!--
            If the product is part of a kit, a discount will be applied to the entire group, and the individual price will not be accurrate.
            We indicate this to the user by applying a strikethrough to the text
        -->
        <span :class="{ 'text-strike': isPartOfKit }">${{ price }}</span>
    </span>
</template>

<script>
export default {
    props: {
        product: {
            type: Object,
            default: () => ({}),
        },
        hideCosts: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        isPartOfKit() {
            return Boolean(this.product?.worksheetKit);
        },
        price() {
            const price = this.hideCosts ? this.product?.customerPrice : this.product?.itemCost;

            if (price <= 0) return '';

            return price.toFixed(2);
        },
    },
};
</script>
