import { watch } from 'vue';
import axios from 'axios';

export const requestStatus = {
    // Not yet called
    IDLE: 'IDLE',
    // In progress (loading)
    PENDING: 'PENDING',
    // Complete - success
    SUCCESS: 'SUCCESS',
    // Complete - error
    ERROR: 'ERROR',
};

/**
 * ==================================
 * Vuex Store Helpers
 * ==================================
 */
/**
 * State - Create a default request status object with IDLE status
 */
export const createRequestStatus = () => ({
    status: requestStatus.IDLE,
    statusCode: 0,
    error: null,
});

/**
 * Mutations - Drop into your store mutations to use the trackRequestStatus helper
 */
export const updateRequestStatus = (state, { name, status, statusCode, error }) => {
    if (!state.requests) {
        throw new Error(`Unable to update request "${name}". Vuex store state is missing a "requests" property.`);
    }
    if (!state.requests?.[name]) {
        throw new Error(`Request "${name}" has not been registered in the store state.`);
    }
    state.requests[name] = { status, statusCode, error };
};

/**
 * Actions - Wraps an AJAX request inside an action to track its status in the store state. Requires the updateRequestStatus mutation.
 * @param {Function} commit - commit function from the Vuex action local context
 * @param {String} requestName - reference to the property name of the request in `state.requests`
 * @param {Promise} request - the AJAX request
 */
export async function trackRequestStatus(commit, requestName, request) {
    commit('updateRequestStatus', { name: requestName, status: requestStatus.PENDING, statusCode: null, error: null });
    try {
        const response = await request;

        // This is wrapped in a timeout so that anything handling the response can update data BEFORE the request is resolved.
        // This approach allows us to conditionally render elements based on the response as soon as a loading state is removed.
        // If we didn't do this, loading state could be removed before store state is updated, and the wrong element(s) might appear for a split second.
        setTimeout(() => {
            commit('updateRequestStatus', {
                name: requestName,
                status: requestStatus.SUCCESS,
                statusCode: response.status,
                error: null,
            });
        });
        return response;
    } catch (error) {
        if (error.code === 'ECONNABORTED' || axios.isCancel(error)) {
            commit('updateRequestStatus', { ...createRequestStatus(), name: requestName });
        } else {
            commit('updateRequestStatus', {
                name: requestName,
                status: requestStatus.ERROR,
                statusCode: error.response?.status,
                error,
            });
        }
        return null;
    }
}

/**
 * ==================================
 * Convenience Functions
 * ==================================
 */
/**
 * Convenience function for checking whether a given request has been made yet
 * @param {Object} requestObject
 * @param {String} requestObject.status
 */
export const isRequestIdle = (requestObject) => requestObject?.status === requestStatus.IDLE;

/**
 * Convenience function for checking whether a given request is in progress
 * @param {Object} requestObject
 * @param {String} requestObject.status
 */
export const isRequestPending = (requestObject) => requestObject?.status === requestStatus.PENDING;

/**
 * Convenience function for checking whether a given request's was successful
 * @param {Object} requestObject
 * @param {String} requestObject.status
 */
export const isRequestSuccess = (requestObject) => requestObject?.status === requestStatus.SUCCESS;

/**
 * Convenience function for checking whether a given request resulted in an error
 * @param {Object} requestObject
 * @param {String} requestObject.status
 */
export const isRequestError = (requestObject) => requestObject?.status === requestStatus.ERROR;

/**
 * Convenience function for checking whether a given request has been completed (error or success)
 * @param {Object} requestObject
 * @param {String} requestObject.status
 */
export const isRequestSettled = (requestObject) => isRequestError(requestObject) || isRequestSuccess(requestObject);

/**
 * Wait for a request to be settled (error or success) before doing something
 * @param {Function} requestRef watcher function that returns the request object
 */
export const requestSettled = (requestRef) =>
    new Promise((resolve) => {
        if (typeof requestRef !== 'function') throw new Error('Request reference needs to be a watcher function that returns a request object.');
        const unwatch = watch(
            requestRef,
            /**
             * @param {Object} requestObject
             * @param {String} requestObject.status
             */
            (request) => {
                if (!isRequestSettled(request)) return;
                setTimeout(() => unwatch());
                resolve(request);
            },
            { immediate: true }
        );
    });
