<template>
    <FcoModal @close="$emit('close')">
        <div slot="body">
            <p>{{ fcoM['rs.quote.newQuotePrompt'] }}</p>
        </div>

        <div slot="footer">
            <button type="button" class="button secondary font-weight-bold" @click="$emit('start-new-quote')">
                {{ fcoM['rs.quote.startNew'] }}
            </button>
            <button type="button" class="button font-weight-bold ml-2" @click="$emit('use-existing-quote')">
                {{ fcoM['rs.quote.addToExistingQuote'] }}
            </button>
        </div>
    </FcoModal>
</template>

<script>
import { FcoModal } from 'fco/src/vue/components/modal';

export default {
    name: 'QuoteSelectionModal',
    emits: ['start-new-quote', 'use-existing-quote', 'close'],
    components: { FcoModal },
};
</script>
