import store from '../store/store';

const ACCOUNT_ID = 200106309927;
const SCRIPT_SRC_URL = '//ee.channels.ocs.oraclecloud.com/js/eesvcs.js';
const CHAT_READY = 'chatReady';

const initEvents = () => {
    window.addEventListener(CHAT_READY, () => {
        store.commit('setIsChatReady', true);
    });

    /* exposes function for Oracle script to call */
    window.dispatchChatReadyEvent = () => {
        window.dispatchEvent(new Event(CHAT_READY));
    };
};
const createScript = () => {
    const oracleScript = document.createElement('script');

    oracleScript.src = SCRIPT_SRC_URL;
    oracleScript.type = 'application/javascript';
    oracleScript.async = true;
    oracleScript.defer = true; // fallback support for browsers that does not support async
    document.head.appendChild(oracleScript);
};
export const openChat = () => {
    /*
    Oracle EE is monitoring this variable for changes,
    and when it changes the 'open chat' behavior is triggered.
    */
    window.chatButtonClickedFlag *= -1;
};
export const initOracleChat = () => {
    window.EESvcs = { eeid: ACCOUNT_ID };
    window.chatButtonClickedFlag = 1;

    initEvents();
    createScript();
};
