<template>
    <div v-on-clickaway="cancelVehicleChange">
        <div class="force-car-overlay" v-if="isForced" @click="cancelForceCar"></div>

        <FcoModal v-if="isRecentVehiclesModalShown" :title-text="fcoM['rs.homeMain.recentVehicles']" size="lg" @close="toggleRecentVehiclesModal">
            <RecentVehicles @vehicle-selected="handleRecentVehicleSelected" v-bind="$props" />
            <button slot="footer" type="button" class="button" @click="toggleRecentVehiclesModal">{{ fcoM['common.close'] }}</button>
        </FcoModal>

        <VehicleCustomerInfo
            v-if="isVehicleInfoShown && currentVehicle.id"
            :vehicle-id="currentVehicle.id"
            :shop-customer-id="shopCustomer ? shopCustomer.id : null"
            :attributes="currentVehicle.answeredAttributes"
            @remove-attribute="removeAttributes([$event])"
            @save="handleVehicleInfoSave"
            @close="toggleVehicleInfoModal"
        />

        <div id="vehicle" :class="['vehicle-lookup', { forced: isForced }]">
            <div class="vehicle-select-container">
                <div v-if="isVehicleError">
                    <FcoSpinner v-if="isVehicleLoading" />
                    <div v-else class="alert alert-danger mb-0">
                        <div>{{ fcoM['rs.homeMain.errorLoadingVehicleSelector'] }}</div>
                        <a href="JavaScript:void(0)" @click="loadCurrentVehicle()">{{ fcoM['common.reload'] }}</a>
                    </div>
                </div>

                <template v-else>
                    <template v-if="currentVehicle && !addVehicleOnlyMode && !changeVehicleMode">
                        <p v-if="currentVehicle.shopVehicleDescriptor" class="d-block d-lg-none">
                            <strong>{{ currentVehicle.shopVehicleDescriptor }}</strong>
                        </p>
                        <p v-if="currentVehicle.vin">
                            <strong>{{ fcoM['rs.homeMain.vinTitle'] || '&nbsp;' }}</strong>
                            <span class="text-uppercase"> {{ currentVehicle.vin }}</span>
                        </p>
                        <div>
                            <div v-if="currentVehicle.answeredAttributes.length || editMode" class="mb-1">
                                <strong class="text-break">{{ fcoM['common.vehicleAttributes'] || '&nbsp;' }}</strong>
                            </div>
                            <div v-if="editMode" class="mb-2">
                                <div class="position-relative mt-2">
                                    <span class="bg-white custom-legend">{{ fcoM['attributes.engine'] }}</span>
                                    <fco-search-select
                                        class="ymm-input d-none d-sm-block"
                                        :value="editEngineSelection.id"
                                        :allow-empty="false"
                                        :placeholder="editEngineSelection.value || `${fcoM['rs.homeMain.selectEngine']} (${fcoM['common.optional']})`"
                                        :grid="true"
                                        :grid-rows="14"
                                        :options="engines"
                                        :mapOption="(option) => ({ label: option.value, value: option.id })"
                                        :menu-class="['ymm-results']"
                                        ref="editEngineSelect"
                                        data-qa="ymm-edit-engine-select"
                                        @focus="handleFocus('engine')"
                                        @blur="handleBlur('engine')"
                                        @select="handleEngineSelect"
                                    />
                                    <select
                                        class="custom-select d-sm-none"
                                        @change="handleEngineSelect(JSON.parse($event.target.value))"
                                        data-qa="ymm-mobile-edit-engine-select"
                                    >
                                        <option value="" disabled selected hidden>{{ fcoM['rs.homeMain.selectEngine'] }}</option>
                                        <option
                                            v-for="engine in engines"
                                            :value="JSON.stringify(engine)"
                                            :key="engine.id"
                                            :selected="editEngineSelection?.id === engine.id"
                                        >
                                            {{ engine.value }}
                                        </option>
                                    </select>
                                </div>

                                <div class="position-relative mt-2">
                                    <span class="bg-white custom-legend">{{ fcoM['attributes.submodel'] }}</span>
                                    <fco-search-select
                                        class="ymm-input d-none d-sm-block"
                                        :value="editSubmodelSelection?.id"
                                        :allow-empty="false"
                                        :placeholder="
                                            tempSubmodelSelected.value || `${fcoM['rs.homeMain.selectSubmodel']} (${fcoM['common.optional']})`
                                        "
                                        :grid="true"
                                        :grid-rows="14"
                                        :options="submodels"
                                        :mapOption="(option) => ({ label: option.value, value: option.id })"
                                        :menu-class="['ymm-results']"
                                        ref="editSubmodelSelect"
                                        data-qa="ymm-edit-submodel-select"
                                        @focus="handleFocus('submodel')"
                                        @blur="handleBlur('submodel')"
                                        @select="handleSubmodelSelect"
                                    />
                                    <select
                                        class="custom-select d-sm-none"
                                        @change="handleSubmodelSelect(JSON.parse($event.target.value))"
                                        data-qa="ymm-mobile-edit-submodel-select"
                                    >
                                        <option value="" disabled selected hidden>{{ fcoM['rs.homeMain.selectSubmodel'] }}</option>
                                        <option
                                            v-for="submodel in submodels"
                                            :value="JSON.stringify(submodel)"
                                            :key="submodel.id"
                                            :selected="editSubmodelSelection?.id === submodel.id"
                                        >
                                            {{ submodel.value }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <ul data-qa="vehicle-attributes-list">
                                <template v-if="editMode">
                                    <VehicleAttribute
                                        v-for="attribute in vehicleSelectedAttributes"
                                        :key="`edit=${attribute.attributeId}`"
                                        :attribute="attribute"
                                        tag="li"
                                        class="ml-0 mb-1 text-break"
                                        :class="{ 'strike-through': attributeRemovalQueue.includes(attribute.attributeId) }"
                                        @remove="toggleAttributeInRemovalQueue(attribute.attributeId)"
                                    />
                                </template>
                                <template v-else>
                                    <li
                                        v-for="attribute in currentVehicle.answeredAttributes"
                                        :key="`nonEdit-${attribute.attributeId}`"
                                        class="ml-0 mb-1 text-break"
                                    >
                                        {{ `${attribute.attributeDescription}: ${attribute.displayDescription}` }}
                                    </li>
                                </template>
                            </ul>
                            <div v-if="editMode">
                                <button @click="updateVehicleAttributes" class="button">{{ fcoM['rs.homeMain.updateVehicle'] }}</button>
                                <button @click="disableVehicleEditMode" class="btn-link font-weight-bold">{{ fcoM['common.cancel'] }}</button>
                            </div>
                        </div>
                        <div v-if="!editMode" class="mb-2">
                            <a href="#" @click.prevent="enabledVehicleEditMode" class="font-weight-bold">
                                <template v-if="currentVehicle.answeredAttributes.length">
                                    <Icon name="pencil" /> {{ fcoM['rs.homeMain.editVehicleAttributes'] }}
                                </template>
                                <template v-else> <Icon name="plus" /> {{ fcoM['rs.homeMain.addVehicleAttributes'] }} </template>
                            </a>
                        </div>
                        <FcoLink
                            to="/vehicle-documents"
                            :href="fcoUrl('/vehicleDocument/viewer.html')"
                            v-if="motorData"
                            data-qa="vehicle-data-link"
                            class="gtm-vehicle-docs qaa-vehicle-data-specs"
                        >
                            <Icon class="icon-up-3 mr-1 text-sm" name="file-text" /><strong>{{ fcoM['rs.homeMain.vehicleDocs'] || '&nbsp;' }}</strong>
                        </FcoLink>
                        <a
                            href="#"
                            id="vsAddCustomer"
                            v-if="!shopCustomer"
                            data-qa="vs-add-customer-link"
                            class="d-block font-weight-bold mt-1 qaa-vehicle-add-customer"
                            @click.prevent="toggleVehicleInfoModal"
                        >
                            <Icon name="user-plus" class="icon-small icon-up-2" /><span class="ml-1">{{
                                fcoM['rs.managecustomers.addCustomer'] || '&nbsp;'
                            }}</span>
                        </a>
                        <a
                            href="#"
                            id="vsAddCustomer"
                            v-else
                            data-qa="vs-edit-customer-link"
                            class="d-block font-weight-bold mt-1 qaa-vehicle-edit-customer"
                            @click.prevent="toggleVehicleInfoModal"
                        >
                            <Icon name="user" class="icon-small icon-up-2" /><span class="ml-1">{{
                                fcoM['rs.managecustomers.editCustomer'] || '&nbsp;'
                            }}</span>
                        </a>
                        <div v-if="shopCustomer" class="mt-3">
                            <h4>{{ fcoM['common.customer'] || '&nbsp;' }}</h4>
                            <FcoLink
                                :to="`/customers/details/${shopCustomer.id}`"
                                :href="fcoUrl(`/customers/#/details/${shopCustomer.id}`)"
                                data-qa="vs-view-customer-link"
                            >
                                <span>{{ shopCustomer.firstName }} {{ shopCustomer.lastName }}</span>
                            </FcoLink>
                        </div>
                        <div class="d-flex justify-content-center flex-wrap mt-3" v-if="!$store.getters.isMitchell1User">
                            <button @click="changeVehicle" class="secondary qaa-vehicle-change mx-1 mb-1" data-qa="vs-change-customer-button">
                                <Icon class="d-none d-sm-inline text-sm icon-up-2" name="car" />
                                {{ fcoM['rs.homeMain.changeVehicle'] || '&nbsp;' }}
                            </button>
                            <button
                                @click="removeSelectedVehicle"
                                data-qa="vs-remove-vehicle-button"
                                class="button secondary qaa-vehicle-clear mx-1 mb-1"
                            >
                                <Icon class="d-none d-sm-inline text-sm icon-up-2" name="trash" />{{
                                    fcoM['rs.getparts.clearSelectedVehicle'] || '&nbsp;'
                                }}
                            </button>
                        </div>
                    </template>
                    <div v-show="!currentVehicle || addVehicleOnlyMode || changeVehicleMode">
                        <a href="#" v-if="changeVehicleMode" @click.prevent="cancelVehicleChange" class="d-inline-block mb-1">
                            <Icon name="chevron-left" /><span class="ml-1">{{ fcoM['rs.getparts.cancelVehicleChange'] }}</span>
                        </a>
                        <PlateToVin v-bind="$props" class="plate-to-vin" @vehicle-selected="handleVehicleSelected" />
                        <div class="vs-section-divider">{{ fcoM['rs.homeMain.or'] || '&nbsp;' }}</div>
                        <YearMakeModel :key="ymmRefreshKey" v-bind="$props" ref="ymm" @vehicle-selected="handleVehicleSelected" />
                        <div class="vs-section-divider">{{ fcoM['rs.homeMain.or'] || '&nbsp;' }}</div>
                        <VinLookup v-bind="$props" class="vin-lookup" @vehicle-selected="handleVehicleSelected" />
                    </div>
                </template>
            </div>
            <div v-if="isForced" class="d-md-flex alert-danger p-3">
                <span class="text-md flex-grow-1 d-inline-flex align-items-center">{{ fcoM['rs.homeMain.selectCar'] }}</span>
                <button @click.prevent="cancelForceCar" class="btn-danger mb-0">{{ fcoM['common.cancel'] }}</button>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import analytics, { GTM } from 'fcoModules/analytics';
import { mapActions, mapState, mapGetters } from 'vuex';
import { FcoModal } from 'fco/src/vue/components/modal';
import VehicleAttribute from '@/common/components/VehicleAttribute.vue';
import VehicleCustomerInfo from '@/common/components/VehicleCustomerInfo.vue';
import { FcoSearchSelect } from 'fco/src/vue/components/searchselect';
import { onClickaway } from 'fco/src/vue/directives/clickaway';
import RecentVehicles from './RecentVehicles.vue';
import PlateToVin from './vehicleSelector/PlateToVin.vue';
import VinLookup from './vehicleSelector/VinLookup.vue';
import YearMakeModel from './vehicleSelector/YearMakeModel.vue';
import Icon from './Icon.vue';
import { isRequestError, isRequestIdle, isRequestPending } from '../store/request-status';
import { VEHICLE_ATTRIBUTE_IDS } from '../constants/vehicle';
import FcoLink from './FcoLink.vue';
import * as attributeService from '../services/attribute-service';

const REMOVE_ID = 'REMOVE';
const DONT_KNOW_ID = '-99';

export default {
    name: 'VehicleSelector',
    emits: ['vehicle-selected'],
    constants: {
        REMOVE_ID,
    },
    props: {
        promptAboutQuote: {
            type: Boolean,
            default: true,
        },
        addVehicleOnlyMode: {
            type: Boolean,
            default: false,
        },
    },
    directives: { onClickaway },
    components: {
        Icon,
        PlateToVin,
        VinLookup,
        YearMakeModel,
        FcoModal,
        RecentVehicles,
        VehicleAttribute,
        VehicleCustomerInfo,
        FcoSearchSelect,
        FcoLink,
    },
    data() {
        return {
            editMode: false,
            changeVehicleMode: false,
            editEngineSelection: '',
            editSubmodelSelection: '',
            tempEngineSelected: '',
            tempSubmodelSelected: '',
            attributeRemovalQueue: [],
            engines: [],
            submodels: [],

            /*
            A counter that forces the YMM component to be recreated when the
            'currentVehicle' property is updated. This prevents any stray popup
            elements from hanging around in the YMM component when the data associated
            with currentVehicle needs to be cleared as seen in RWD-14815.
            */
            ymmRefreshKey: 0,
        };
    },
    computed: {
        ...mapState('vehicleSelector', {
            vehicleRequest: (state) => state.requests.getCurrentVehicle,
            shopRequest: (state) => state.requests.getCurrentShop,
            isRecentVehiclesModalShown: (state) => state.isRecentVehiclesModalShown,
            isForced: (state) => !!state.forceUrl,
            isOpenDefault: ({ isOpenDefault }) => isOpenDefault,
            currentVehicle: (state) => state.currentVehicle,
            isVehicleInfoShown: (state) => state.isVehicleInfoShown,
            isEditSelectionOpen: (state) => state.isEditSelectionOpen,
            preventNavigationOnVehicleUpdate: (state) => state.preventNavigationOnVehicleUpdate,
        }),
        ...mapState({
            motorData: (state) => state.currentShop?.motorData,
            quoteDetails: (state) => state.miniQuote.quoteDetails,
            isSPA: (state) => state.isSPA,
        }),
        ...mapGetters('vehicleSelector', ['vehicleEngine', 'vehicleSubmodel']),
        currentLocale() {
            return this.$store.state.i18n.locale;
        },
        currentQuoteId() {
            return this.quoteDetails.worksheetHeaderId;
        },
        isVehicleError() {
            return isRequestError(this.vehicleRequest);
        },
        isVehicleLoading() {
            return isRequestPending(this.vehicleRequest);
        },
        forceCarMsg() {
            return this.fcoM['rs.homeMain.selectCar'];
        },
        shopCustomer() {
            return this.$store.getters['vehicleSelector/shopCustomer'];
        },
        vehicleSelectedAttributes() {
            return this.currentVehicle.answeredAttributes.filter(
                (attr) => attr.attributeId !== VEHICLE_ATTRIBUTE_IDS.ENGINE && attr.attributeId !== VEHICLE_ATTRIBUTE_IDS.SUBMODEL
            );
        },
        vehicleIsSelected() {
            return !!this.currentVehicle?.vehicleId;
        },
    },
    methods: {
        ...mapActions('vehicleSelector', ['getAnsweredAttributesTranslated']),

        handleFocus(inputType) {
            this.$store.dispatch('vehicleSelector/setVsProp', { prop: 'isYearSelectorOpen', value: true });
            const upperCaseInputType = inputType.charAt(0).toUpperCase() + inputType.slice(1);
            const tempValue = `temp${upperCaseInputType}Selected`;
            const value = `edit${upperCaseInputType}Selection`;
            this[tempValue] = this[value];
            this[value] = '';

            analytics({
                event: GTM.Action.VEHICLE_SELECTOR,
                data: {
                    eventAction: `YMM Edit ${inputType} Focus`,
                },
            });
        },
        handleBlur(inputType) {
            this.$store.dispatch('vehicleSelector/setVsProp', { prop: 'isYearSelectorOpen', value: false });
            const upperCaseInputType = inputType.charAt(0).toUpperCase() + inputType.slice(1);
            const tempValue = `temp${upperCaseInputType}Selected`;
            const value = `edit${upperCaseInputType}Selection`;

            if (!this[value]) {
                this[value] = this[tempValue];
            }
        },
        cancelVehicleChange() {
            this.changeVehicleMode = false;
            this.$store.dispatch('vehicleSelector/setVsProp', { prop: 'isYearSelectorOpen', value: false });
        },
        cancelForceCar() {
            this.$store.dispatch('vehicleSelector/forceCar', false);
        },
        async enabledVehicleEditMode() {
            let engineRequestUrl = this.fcoUrl(`/vehicle/select/engines?baseVehicleId=${this.currentVehicle.vehicleId}`);
            if (this.vehicleSubmodel) {
                engineRequestUrl += `&subModelId=${this.vehicleSubmodel.valueId}`;
            }
            try {
                const { data } = await axios.get(engineRequestUrl);
                this.engines = data;
            } catch {
                this.$fcoToast.error(this.fcoM['rs.homeMain.vsGenericError']);
            }

            // only add option to select no engine if the user already has one selected that they can remove and it doesn't already
            // exist in the list
            if (this.vehicleEngine && !this.engines.some((engine) => engine.id === REMOVE_ID)) {
                this.engines.unshift({ id: REMOVE_ID, value: this.fcoM['rs.homeMain.noEngineSelected'] });
            }

            // if an engine was already selected, set that engine as the edit engine selection
            if (this.vehicleEngine) {
                this.editEngineSelection = this.engines.find((engine) => engine.id === this.vehicleEngine.valueId);
            }

            // if we already have an engine selected, we need to request submodel data
            let submodelRequestUrl = this.fcoUrl(`/vehicle/select/submodels?baseVehicleId=${this.currentVehicle.vehicleId}`);
            if (this.vehicleEngine) {
                submodelRequestUrl += `&engineId=${this.vehicleEngine.valueId}`;
            }
            try {
                const { data } = await axios.get(submodelRequestUrl);
                this.submodels = data;
            } catch {
                this.$fcoToast.error(this.fcoM['rs.homeMain.vsGenericError']);
            }
            // if a submodel was already selected, set that submodel as the edit submodel selection
            if (this.vehicleSubmodel) {
                this.editSubmodelSelection = this.submodels.find((submodel) => submodel.id === this.vehicleSubmodel.valueId);
            }

            this.editMode = true;
        },
        disableVehicleEditMode() {
            this.editEngineSelection = '';
            this.editSubmodelSelection = '';
            this.attributeRemovalQueue = [];
            this.editMode = false;
        },
        async handleAnsweredAttributesLanguage() {
            if (!this.currentVehicle) return;

            try {
                const { vehicleId, id: worksheetVehicleId } = this.currentVehicle;
                const { currentQuoteId: worksheetId } = this;

                if (vehicleId && worksheetId) {
                    await this.getAnsweredAttributesTranslated({ worksheetId, worksheetVehicleId });
                }
            } catch (error) {
                this.$fcoToast.error(this.fcoM['rs.homeMain.vsGenericError']);
            }
        },
        async handleEngineSelect(engine) {
            this.editEngineSelection = engine;

            if (engine.id !== REMOVE_ID) {
                let requestUrl = this.fcoUrl(`/vehicle/select/submodels?baseVehicleId=${this.currentVehicle.vehicleId}&engineId=${engine.id}`);

                if (this.vehicleSubmodel) {
                    requestUrl += `&subModelId=${this.vehicleSubmodel.id}`;
                }

                try {
                    const { data } = await axios.get(requestUrl);
                    this.submodels = data;

                    // if there is a temp edit submodel selected, and it doesn't exist in the new submodel list then clear that selection
                    if (
                        this.editSubmodelSelection &&
                        this.editSubmodelSelection.id !== DONT_KNOW_ID &&
                        !data.find((submodel) => submodel.id === this.editSubmodelSelection.id)
                    ) {
                        this.editSubmodelSelection = '';
                    }

                    analytics({
                        event: GTM.Action.VEHICLE_SELECTOR,
                        data: {
                            eventAction: 'YMM Edit Engine Select',
                        },
                    });
                } catch (err) {
                    this.$fcoToast.error(this.fcoM['rs.homeMain.vsGenericError']);
                }
            }
        },
        async handleSubmodelSelect(submodel) {
            this.editSubmodelSelection = submodel;

            let requestUrl = this.fcoUrl(`/vehicle/select/engines?baseVehicleId=${this.currentVehicle.vehicleId}`);
            if (submodel.id !== DONT_KNOW_ID) {
                requestUrl += `&subModelId=${submodel.id}`;
            }
            try {
                const { data } = await axios.get(requestUrl);
                this.engines = data;

                // if there is a temp edit submodel selected, and it doesn't exist in the new submodel list then clear that selection
                if (
                    this.editEngineSelection &&
                    this.editEngineSelection?.id !== REMOVE_ID &&
                    !data.find((engine) => engine.id === this.editEngineSelection.id)
                ) {
                    this.editEngineSelection = '';
                }
            } catch {
                this.$fcoToast.error(this.fcoM['rs.homeMain.vsGenericError']);
            }

            analytics({
                event: GTM.Action.VEHICLE_SELECTOR,
                data: {
                    eventAction: 'YMM Edit Submodel Select',
                },
            });
        },
        async updateVehicleAttributes() {
            this.editMode = false;

            if (this.editEngineSelection?.id === REMOVE_ID) {
                this.attributeRemovalQueue.push(VEHICLE_ATTRIBUTE_IDS.ENGINE);

                await this.removeAttributes(this.attributeRemovalQueue);

                if (!this.preventNavigationOnVehicleUpdate) {
                    window.location.reload();
                } else {
                    // since the remove attribute endpoint doesn't return vehicle data to get reset in store, kick off
                    // the action to get current vehicle again, this will trigger anything watchign for vehicle changes
                    // in the store.
                    this.$store.dispatch('vehicleSelector/getCurrentVehicle');
                }
                return;
            }

            // if user started with an engine selected, switched to a new engine choice and never selected a new submodel
            // then we should clear the old submodel if one was selected originally
            if (this.vehicleEngine && this.engineSelected?.id !== this.vehicleEngine.valueId && !this.editSubmodelSelection && this.vehicleSubmodel) {
                this.attributeRemovalQueue.push(VEHICLE_ATTRIBUTE_IDS.SUBMODEL);
            }

            // check for non engine/submodel attributes changes first, remove those before requesting engine/submodel update
            await this.removeAttributes(this.attributeRemovalQueue);

            const updatePayload = {};
            if (this.editEngineSelection && this.editEngineSelection.id !== REMOVE_ID) updatePayload.engineId = this.editEngineSelection.id;
            if (this.editSubmodelSelection?.id) updatePayload.submodelId = this.editSubmodelSelection.id;

            if (Object.keys(updatePayload).length) {
                try {
                    await this.$store.dispatch('vehicleSelector/selectEngineSubmodel', updatePayload);
                    this.editMode = false;
                } catch {
                    this.$fcoToast.error(this.fcoM['rs.homeMain.vsGenericError']);
                }
            }
        },
        loadCurrentVehicle() {
            this.$store.dispatch('vehicleSelector/getCurrentVehicle');
        },
        toggleRecentVehiclesModal() {
            this.$store.commit('vehicleSelector/setIsRecentVehiclesModalShown', !this.isRecentVehiclesModalShown);
        },
        toggleVehicleInfoModal() {
            this.$store.commit('vehicleSelector/setIsVehicleInfoShown', !this.isVehicleInfoShown);
        },
        async removeAttributes(attributeIds) {
            const attributesToRemove = [...attributeIds];

            let containsSubmodel = false;
            let containsEngine = false;

            attributeIds.forEach((attr) => {
                if (attr === VEHICLE_ATTRIBUTE_IDS.SUBMODEL) containsSubmodel = true;
                if (attr === VEHICLE_ATTRIBUTE_IDS.ENGINE) containsEngine = true;
            });

            if (containsEngine && this.vehicleSubmodel && containsSubmodel) {
                // submodel needs to be before engine in the array to be removed correctly
                attributesToRemove.splice(attributesToRemove.indexOf(VEHICLE_ATTRIBUTE_IDS.SUBMODEL), 1);
                attributesToRemove.unshift(VEHICLE_ATTRIBUTE_IDS.SUBMODEL);
            }

            try {
                // The endpoint to clear attributes seems to require synchronous requests to clear attributes correctly.
                for await (const attributeId of attributesToRemove) {
                    await attributeService.remove({ attributeId, updateAttributeQuestion: true });
                }
                this.$store.commit('vehicleSelector/removeAttributes', attributesToRemove);
            } catch (error) {
                this.$fcoToast.error(this.fcoM['rs.homeMain.errorRemovingAttribute']);
            }
        },
        toggleAttributeInRemovalQueue(attributeId) {
            if (this.attributeRemovalQueue.includes(attributeId)) {
                this.attributeRemovalQueue = this.attributeRemovalQueue.filter((id) => id !== attributeId);
            } else {
                this.attributeRemovalQueue.push(attributeId);
            }
        },
        async removeSelectedVehicle() {
            try {
                await this.$store.dispatch('vehicleSelector/clearCurrentVehicle');
                this.$store.dispatch('miniQuote/clearQuote');

                // reset edit mode and all vehicle attribute lookup or manually selected data anytime vehicle changes.
                // enableEditMode method should reset handle getting the right data.
                this.editMode = false;
                this.resetEditVehicleAttributes();
            } catch (err) {
                this.$fcoToast.error(this.fcoM['rs.homeMain.errorChangeVehicle']);
            }
        },
        async changeVehicle() {
            this.changeVehicleMode = true;
            await this.$nextTick();
            this.$store.dispatch('vehicleSelector/setVsProp', { prop: 'isYearSelectorOpen', value: true });
        },
        handleVehicleInfoSave({ firstName, lastName, selectedShopCustomerId }) {
            if (selectedShopCustomerId && firstName && lastName) {
                this.$store.commit('vehicleSelector/setShopCustomer', { id: selectedShopCustomerId, firstName, lastName });
                this.$store.commit('vehicleSelector/setCurrentVehicle', {
                    ...this.currentVehicle,
                    shopCustomerFirst: firstName,
                    shopCustomerLast: lastName,
                    shopCustomerId: selectedShopCustomerId,
                });
            }
            this.toggleVehicleInfoModal();
        },
        resetEditVehicleAttributes() {
            this.engines = [];
            this.submodels = [];
            this.editEngineSelection = '';
            this.editSubmodelSelection = '';
            this.tempSubmodelSelected = '';
            this.tempEngineSelected = '';
        },
        handleVehicleSelected(vehicle) {
            this.changeVehicleMode = false;
            this.$emit('vehicle-selected', vehicle);
        },
        handleRecentVehicleSelected(vehicle) {
            this.toggleRecentVehiclesModal();
            this.handleVehicleSelected(vehicle);
        },
    },
    watch: {
        isForced(isForced) {
            if (isForced) {
                analytics({
                    event: GTM.Action.VEHICLE_SELECTOR,
                    data: {
                        eventAction: 'Force Selection',
                    },
                });
            }
        },
        currentLocale() {
            this.handleAnsweredAttributesLanguage();
        },
        async isEditSelectionOpen(openEditInput) {
            if (!openEditInput || !this.vehicleIsSelected) {
                this.$store.commit('vehicleSelector/setVsProp', { prop: 'isEditSelectionOpen', value: false });
                return;
            }

            this.changeVehicleMode = false;
            await this.enabledVehicleEditMode();

            if (!this.vehicleEngine) {
                this.$refs.editEngineSelect.focus();
            } else if (!this.vehicleSubmodel) {
                this.$refs.editSubmodelSelect.focus();
            }

            this.$store.commit('vehicleSelector/setVsProp', { prop: 'isEditSelectionOpen', value: false });
        },
        currentVehicle(newVehicle, oldVehicle) {
            // if vehicle is changed or removed reset edit attributes for pages that don't reload so old data doesn't show
            if (newVehicle?.id !== oldVehicle?.id) {
                this.ymmRefreshKey += 1;
                this.resetEditVehicleAttributes();
            }
            if (this.isForced && newVehicle !== null) this.$store.dispatch('vehicleSelector/forceCar', false);
        },
    },
    async created() {
        if (isRequestIdle(this.shopRequest)) this.$store.dispatch('getCurrentShop');
        if (isRequestIdle(this.vehicleRequest)) this.loadCurrentVehicle();
    },
};
</script>

<style lang="scss" scoped>
@import '~scssVariables/mixins';
@import '~scssVariables/config';

$z-index-vs: 2;
$z-index-vs-forced: $z-index-drawer + 2;

.vehicle-lookup {
    background: $white;
    position: relative;
    top: -1px;
    width: 100%;
    z-index: $z-index-vs;

    .icon-up-2 {
        top: -2px;
    }

    .icon-up-3 {
        top: -3px;
    }

    .icon-small {
        width: 14px;
        height: 14px;
    }

    @include respond-up-to(sm) {
        position: absolute;
        width: 300px;
        top: 51px;
    }

    /* fix for dropdown extending past button */
    @include respond(md, lg) {
        width: 100%;
    }

    .vehicle-select-container {
        @include drop-shadow;
        padding: 15px;
    }

    &:not(.forced) .vehicle-select-container {
        border: $border-gray;
    }

    .vs-section-divider {
        overflow: hidden;
        text-align: center;
        color: $gray-300;
        font-size: 1.2em;

        &:before,
        &:after {
            background-color: $gray-300;
            content: '';
            display: inline-block;
            height: 1px;
            position: relative;
            vertical-align: middle;
            width: 50%;
        }

        &:before {
            right: 0.5em;
            margin-left: -50%;
        }

        &:after {
            left: 0.5em;
            margin-right: -50%;
        }
    }

    &.forced {
        z-index: $z-index-vs-forced;
        border: $border-red;
    }
}

.force-car-overlay {
    z-index: $z-index-vs-forced - 1;
    background: rgba(0, 0, 0, 0.75);
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    animation: fadeInAnimationFrames 250ms ease-out;
}
</style>
