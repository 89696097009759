var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    {
      attrs: {
        "data-qa": `banner-zone-${_vm.name}`,
      },
    },
    [
      _vm.isLoading || (_vm.placeholder && _vm.hasError)
        ? [
            _vm.placeholder
              ? _c("div", {
                  staticClass: "banner-zone-placeholder",
                  style: {
                    aspectRatio: _vm.aspectRatio,
                  },
                })
              : _vm._e(),
          ]
        : _vm.banners.length
        ? [
            _vm._t("beforeImages", null, {
              banners: _vm.banners,
            }),
            _vm._v(" "),
            _vm.carousel
              ? _c(
                  "Carousel",
                  _vm._b(
                    {
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (banner) {
                              return [
                                _c("BannerImage", {
                                  attrs: {
                                    bannerImage: banner,
                                    maxHeight: _vm.maxHeight,
                                    maxWidth: _vm.maxWidth,
                                  },
                                }),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        3665805315
                      ),
                    },
                    "Carousel",
                    Object.assign(
                      {
                        slides: _vm.banners,
                      },
                      _vm.carouselProps
                    ),
                    false
                  )
                )
              : _vm._l(_vm.banners, function (banner) {
                  return _c("BannerImage", {
                    key: banner.identifier,
                    staticClass: "banner-image",
                    attrs: {
                      bannerImage: banner,
                      maxHeight: _vm.maxHeight,
                      maxWidth: _vm.maxWidth,
                    },
                  })
                }),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }