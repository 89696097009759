var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "FcoDropdown",
    {
      attrs: {
        show: _vm.isOpen,
      },
      on: {
        "update:show": function ($event) {
          _vm.isOpen = $event
        },
      },
      scopedSlots: _vm._u([
        {
          key: "toggle",
          fn: function () {
            return [
              _c(
                "button",
                {
                  staticClass: "language-select-button",
                  class: [
                    {
                      active: _vm.isOpen,
                    },
                    _vm.buttonClass,
                  ],
                  attrs: {
                    type: "button",
                  },
                  on: {
                    click: function ($event) {
                      _vm.isOpen = !_vm.isOpen
                    },
                  },
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "language-select-label",
                      class: _vm.labelClass,
                    },
                    [_vm._v(_vm._s(_vm.fcoM["common.language"]))]
                  ),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticClass: "no-wrap",
                    },
                    [
                      _c(
                        "svg",
                        {
                          staticClass: "i18n-icon",
                          attrs: {
                            xmlns: "http://www.w3.org/2000/svg",
                            fill: "none",
                            stroke: "currentColor",
                            "stroke-width": "1.5",
                            viewBox: "0 0 24 24",
                          },
                        },
                        [
                          _c("path", {
                            attrs: {
                              "stroke-linecap": "round",
                              "stroke-linejoin": "round",
                              d: "M12 21a9.004 9.004 0 0 0 8.716-6.747M12 21a9.004 9.004 0 0 1-8.716-6.747M12 21c2.485 0 4.5-4.03 4.5-9S14.485 3 12 3m0 18c-2.485 0-4.5-4.03-4.5-9S9.515 3 12 3m0 0a8.997 8.997 0 0 1 7.843 4.582M12 3a8.997 8.997 0 0 0-7.843 4.582m15.686 0A11.953 11.953 0 0 1 12 10.5c-2.998 0-5.74-1.1-7.843-2.918m15.686 0A8.959 8.959 0 0 1 21 12c0 .778-.099 1.533-.284 2.253m0 0A17.919 17.919 0 0 1 12 16.5a17.92 17.92 0 0 1-8.716-2.247m0 0A9.015 9.015 0 0 1 3 12c0-1.605.42-3.113 1.157-4.418",
                            },
                          }),
                        ]
                      ),
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.currentLocaleLabel) +
                          "\n                "
                      ),
                      _c("Icon", {
                        attrs: {
                          name: "chevron-down",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _vm._v(" "),
      _vm._l(_vm.localeOptions, function (_ref) {
        var label = _ref.label,
          value = _ref.value
        return _c(
          "button",
          {
            key: value,
            staticClass: "dropdown-item mb-0 rounded-0",
            class: {
              active: _vm.isCurrentLocal(value),
            },
            attrs: {
              type: "button",
            },
            on: {
              click: function ($event) {
                return _vm.handleSelect(value)
              },
            },
          },
          [_vm._v("\n        " + _vm._s(label) + "\n    ")]
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }