<template>
    <component :is="tag" class="attribute">
        <div v-if="canRemove" class="attribute-action">
            <Icon v-if="loading" name="refresh" />
            <button v-else type="button" class="remove-attr-btn" @click.prevent="$emit('remove')">
                <Icon class="clickable red text-sm" name="trash" />
            </button>
        </div>
        <div class="flex-fill">
            <span class="attribute-name">{{ name }}</span
            >:
            <span class="attribute-value">{{ value }}</span>
        </div>
    </component>
</template>

<script>
import Icon from '@/common/components/Icon.vue';

export default {
    name: 'Attribute',
    props: {
        name: {
            type: String,
            required: true,
        },
        value: {
            type: String,
            required: true,
        },
        canRemove: {
            type: Boolean,
            default: true,
        },
        tag: {
            type: String,
            default: 'div',
        },
        loading: {
            type: Boolean,
            default: false,
        },
    },
    components: { Icon },
};
</script>

<style scoped lang="scss">
.attribute {
    display: flex;
    align-items: baseline;
    line-height: 1.3;
}

.attribute-action {
    margin-right: 0.25rem;
}

.remove-attr-btn {
    margin-bottom: 0;

    &,
    &:hover,
    &:focus {
        padding: 0;
        background: none transparent;
        border: 0 none;
    }
}
</style>
