import { ErrorKeys } from '../constants/login';

export default {
    computed: {
        loginErrorKey() {
            if (!this.isSPA) {
                return new URLSearchParams(window.location.search).get('errorKey');
            }
            return this.$route.query.errorKey;
        },
        loginErrorMessagesByKey() {
            return {
                [ErrorKeys.OAUTH_LOGIN_ERROR]: this.fcoM['oauth.loginError'],
                [ErrorKeys.PROMO_ADMIN_LOGIN_ERROR]: this.fcoM['promoAdmin.loginError'],
                [ErrorKeys.LOGIN_AUTHENTICATION_FAILED]: this.fcoM['rs.home.incorrectLogin'],
                [ErrorKeys.INVALID]: this.fcoM['rs.home.incorrectLogin'],
                [ErrorKeys.LOGIN_REQUIRED]: this.fcoM['rs.home.loginRequired'],
                [ErrorKeys.SHOP_REFERRAL_LOGIN_ERROR]: this.fcoM['rs.home.sso.shopreferral.loginError'],
                [ErrorKeys.STATEMENTS_LOGIN_ERROR]: this.fcoM['rs.home.sso.statements.loginError'],
                [ErrorKeys.LOGIN_TEMP_PASSWORD_EXPIRED]: this.fcoM['rs.home.temporaryPasswordExpired'],
            };
        },
        loginErrorMessage() {
            if (!this.loginErrorKey) return '';
            return this.loginErrorMessagesByKey[this.loginErrorKey] || '';
        },
    },
};
