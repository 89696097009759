var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "header",
    {
      staticClass: "fco-header public-header",
    },
    [
      _c(
        "h1",
        {
          staticClass: "sr-only",
        },
        [_vm._v(_vm._s(_vm.fcoM["rs.home.publicHeader"]))]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "fco-header-container d-md-flex",
        },
        [
          _c("HeaderBrandLink"),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "fco-header-right d-none d-md-flex",
            },
            [
              _c(
                "div",
                {
                  staticClass: "pro-services d-none d-md-block",
                },
                [_vm._v(_vm._s(_vm.fcoM["rs.home.professionalServices"]))]
              ),
            ]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }