// Possible error codes passed to the FE as a URL query parameter
export const ErrorKeys = {
    OAUTH_LOGIN_ERROR: 'OAUTH_LOGIN_ERROR',
    PROMO_ADMIN_LOGIN_ERROR: 'PROMO_ADMIN_LOGIN_ERROR',
    LOGIN_AUTHENTICATION_FAILED: 'LOGIN_AUTHENTICATION_FAILED',
    INVALID: 'INVALID',
    LOGIN_FAILURE_ACCOUNT_LOCKED: 'LOGIN_FAILURE_ACCOUNT_LOCKED',
    LOGIN_REQUIRED: 'LOGIN_REQUIRED',
    LOGIN_TEMP_PASSWORD_EXPIRED: 'LOGIN_TEMP_PASSWORD_EXPIRED',
    SHOP_REFERRAL_LOGIN_ERROR: 'SHOP_REFERRAL_LOGIN_ERROR',
    STATEMENTS_LOGIN_ERROR: 'STATEMENTS_LOGIN_ERROR',
};
