/* eslint no-shadow: ['error', { 'allow': ['state', 'getters'] }] */

function getLocalStorageLookup() {
    return JSON.parse(localStorage.getItem('lookupDetails'));
}

function saveLocalStorageLookup(value) {
    localStorage.setItem('lookupDetails', JSON.stringify(value));
}

function deleteLookupDetails() {
    localStorage.removeItem('lookupDetails');
}

export const state = {
    lookupParts: null,
    lookupResolvedParts: null,
    lookupFullResolvedDetails: null,
    lookupVehicle: null,
    lookupRetainAnswers: null,
    lookupNonRetainAnswers: null,
    lookupOrigin: null,
    lookupSiblings: null,
    lookupLocale: null,
};

export const mutations = {
    setLookupDetails(
        state,
        {
            partTypes = null,
            resolvedPartTypes = null,
            fullResolvedDetails = null,
            vehicle = null,
            retainAnswerAttributes = null,
            nonRetainAnswerAttributes = null,
            origin = null,
            siblingPartTypes = null,
            locale,
        }
    ) {
        state.lookupParts = partTypes;
        state.lookupResolvedParts = resolvedPartTypes;
        state.lookupFullResolvedDetails = fullResolvedDetails;
        state.lookupVehicle = vehicle;
        state.lookupRetainAnswers = retainAnswerAttributes;
        state.lookupNonRetainAnswers = nonRetainAnswerAttributes;
        state.lookupOrigin = origin;
        state.lookupSiblings = siblingPartTypes;
        state.lookupLocale = locale;
    },
    setPartLookup(
        state,
        {
            partTypes = null,
            resolvedPartTypes = null,
            fullResolvedDetails = null,
            vehicle = null,
            retainAnswerAttributes = null,
            nonRetainAnswerAttributes = null,
            origin = null,
            siblingPartTypes = null,
            locale,
        }
    ) {
        state.lookupParts = partTypes;
        state.lookupResolvedParts = resolvedPartTypes;
        state.lookupFullResolvedDetails = fullResolvedDetails;
        state.lookupVehicle = vehicle;
        state.lookupRetainAnswers = retainAnswerAttributes;
        state.lookupNonRetainAnswers = nonRetainAnswerAttributes;
        state.lookupOrigin = origin;
        state.lookupSiblings = siblingPartTypes;
        state.lookupLocale = locale;
    },
    setRetainAnswers(state, value) {
        state.lookupRetainAnswers = value;
    },
    setNonRetainAnswers(state, value) {
        state.lookupNonRetainAnswers = value;
    },
};

export const actions = {
    // should be used anytime lookup details need to be saved or updated to persist through routing
    savePartLookup(
        { commit, rootState },
        {
            partTypes = null,
            resolvedPartTypes = null,
            vehicle = null,
            origin = null,
            siblingPartTypes = null,
            fullResolvedDetails = null,
            retainAnswerAttributes = null,
            nonRetainAnswerAttributes = null,
        }
    ) {
        const lookupDetails = {
            vehicle,
            partTypes,
            origin,
            resolvedPartTypes,
            fullResolvedDetails,
            siblingPartTypes,
            retainAnswerAttributes,
            nonRetainAnswerAttributes,
            locale: rootState.i18n.locale,
        };
        saveLocalStorageLookup(lookupDetails);
        commit('setPartLookup', lookupDetails);
    },
    saveRetainAttributeAnswer({ commit }, retainAnswer) {
        const lookupDetails = getLocalStorageLookup();
        if (lookupDetails.retainAnswerAttributes) lookupDetails.retainAnswerAttributes.push(retainAnswer);
        else lookupDetails.retainAnswerAttributes = [retainAnswer];
        saveLocalStorageLookup(lookupDetails);
        commit('setRetainAnswers', lookupDetails.retainAnswerAttributes);
    },
    saveNonRetrainAttributeAnswer({ commit }, nonRetainAnswer) {
        const lookupDetails = getLocalStorageLookup();
        if (lookupDetails.nonRetainAnswerAttributes) {
            // add safety check to replace a duplicate if answer exists for that part type
            const duplicateIndex = lookupDetails.nonRetainAnswerAttributes.findIndex(
                (answer) => answer.attributeId === nonRetainAnswer.attributeId && answer.partTypeId === nonRetainAnswer.partTypeId
            );
            if (duplicateIndex !== -1) lookupDetails.nonRetainAnswerAttributes[duplicateIndex] = nonRetainAnswer;
            else lookupDetails.nonRetainAnswerAttributes.push(nonRetainAnswer);
        } else {
            lookupDetails.nonRetainAnswerAttributes = [nonRetainAnswer];
        }
        saveLocalStorageLookup(lookupDetails);
        commit('setNonRetainAnswers', lookupDetails.nonRetainAnswerAttributes);
    },
    // used anytime we want localStorage lookup details available in vuex state
    getLookupDetails({ commit }) {
        const lookupDetails = getLocalStorageLookup();
        if (lookupDetails) commit('setLookupDetails', lookupDetails);
    },
    removeNonRetainAttribute({ commit }, { attributeId, valueId, partTypeId }) {
        const lookupDetails = getLocalStorageLookup();
        const updatedNonRetainAnswers = lookupDetails.lookupNonRetainAnswers.filter(
            (answer) => answer.valueId !== valueId && answer.attributeId !== attributeId && answer.partTypeId !== partTypeId
        );
        commit('setNonRetainAnswers', updatedNonRetainAnswers);
        lookupDetails.lookupNonRetainAnswers = updatedNonRetainAnswers;
        saveLocalStorageLookup(lookupDetails);
    },
    resetNonRetainAttributes({ commit }) {
        const lookupDetails = getLocalStorageLookup();
        if (lookupDetails.hasOwnProperty('nonRetainAnswerAttributes')) {
            delete lookupDetails.nonRetainAnswerAttributes;
            saveLocalStorageLookup(lookupDetails);
            commit('setLookupDetails', lookupDetails);
        }
    },
    resetRetainAttributes({ commit }) {
        const lookupDetails = getLocalStorageLookup();
        if (lookupDetails.retainAnswerAttributes) {
            delete lookupDetails.retainAnswerAttributes;
            saveLocalStorageLookup(lookupDetails);
            commit('setLookupDetails', lookupDetails);
        }
    },
    clearLookupDetails({ commit }) {
        deleteLookupDetails();
        commit('setLookupDetails', {});
    },
    updateNonRetainAnswersByPartType({ commit }, { partTypeId, newAnswers }) {
        const lookupDetails = getLocalStorageLookup();

        // get list of unique attributeIds to remove for this part type from the update answers
        const uniqueAttributeIds = [...new Set(newAnswers.map((answer) => answer.attributeId))];

        // get a backup of answers that don't have anything to do with this partTypeId
        const backupAnswersToKeep = lookupDetails.nonRetainAnswerAttributes.filter((answer) => answer.partTypeId !== partTypeId);

        // get a list that is safe to modify with answers for this partTypeId
        let answersForPartType = lookupDetails.nonRetainAnswerAttributes.filter((answer) => answer.partTypeId === partTypeId);

        // get rid of all saved answers that our new answers will be replacing
        uniqueAttributeIds.forEach((uniqueAttributeId) => {
            answersForPartType = answersForPartType.filter((answer) => answer.attributeId !== uniqueAttributeId);
        });

        const finalList = [...backupAnswersToKeep, ...answersForPartType, ...newAnswers];

        lookupDetails.nonRetainAnswerAttributes = finalList;
        saveLocalStorageLookup(lookupDetails);
        commit('setNonRetainAnswers', finalList);
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
};
