// TODO remove this file when we stop using LivePerson
/*
LivePerson is the AI support chat platform we use.
Clicking our 'Chat For Help' buttons opens a LivePerson chat popup.
The LivePerson script is separate from FCO, so it's necessary to alert LivePerson
of various events that occur in FCO, and vice-versa.
*/
import { Locale } from '../constants/user';

const LIVE_PERSON = 'liveperson';
const livePerson = {
    isLoaded: false,
    isAuthenticated: false,
    locale: Locale.EN,
    preferencesQueued: false,
};

const setLivePersonProperty = (key, value, event) => {
    livePerson[key] = value;

    if (!livePerson.isLoaded) {
        livePerson.preferencesQueued = true;

        return;
    }

    const customEvent = new CustomEvent(`${LIVE_PERSON}-${event}`, { detail: value });
    window.dispatchEvent(customEvent);
};

export const setLivePersonLocale = (locale) => {
    // alerts LivePerson to switch chat language
    setLivePersonProperty('locale', locale, 'locale');
};

export const setLivePersonAuthenticated = (isAuthenticated) => {
    // alerts LivePerson to show authenticated area chat links
    setLivePersonProperty('isAuthenticated', isAuthenticated, 'authenticated');
};

const listenForLivePersonLoaded = () => {
    window.addEventListener(`${LIVE_PERSON}-loaded`, () => {
        livePerson.isLoaded = true;

        if (livePerson.preferencesQueued) {
            setLivePersonAuthenticated(livePerson.isAuthenticated);
            setLivePersonLocale(livePerson.locale);
            livePerson.preferencesQueued = false;
        }
    });
};

listenForLivePersonLoaded();
