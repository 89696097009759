var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c("Attribute", {
    attrs: {
      tag: _vm.tag,
      canRemove: _vm.canRemove,
      name: _vm.attributeName,
      value: _vm.attributeValue,
      loading: _vm.loading,
    },
    on: {
      remove: function ($event) {
        return _vm.$emit("remove", _vm.attribute)
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }