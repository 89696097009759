var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(`icon-${_vm.name}`, {
    tag: "component",
    class: ["icon", _vm.name],
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }