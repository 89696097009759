var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    _vm._l(_vm.activeBroadcastMessages, function (_ref) {
      var content = _ref.content,
        description = _ref.description,
        linkUrl = _ref.linkUrl,
        messageId = _ref.messageId
      return _c(
        "div",
        {
          key: messageId,
          staticClass: "alert alert-warning mb-0 d-flex",
        },
        [
          linkUrl !== ""
            ? _c(
                "a",
                {
                  staticClass:
                    "message-content d-block text-decoration-none text-center text-reset",
                  attrs: {
                    href: linkUrl,
                  },
                },
                [
                  _c("div", [
                    _c("h2", [_vm._v(_vm._s(description))]),
                    _vm._v(" "),
                    _c("div", [_vm._v(_vm._s(content))]),
                  ]),
                ]
              )
            : _c(
                "div",
                {
                  staticClass: "message-content text-center",
                },
                [
                  _c("h2", [_vm._v(_vm._s(description))]),
                  _vm._v(" "),
                  _c("div", [_vm._v(_vm._s(content))]),
                ]
              ),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "text-lg",
              attrs: {
                href: "JavaScript:void(0)",
              },
              on: {
                click: function ($event) {
                  return _vm.dismissMessage(messageId)
                },
              },
            },
            [
              _c("Icon", {
                staticClass: "red",
                attrs: {
                  name: "times",
                },
              }),
            ],
            1
          ),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }